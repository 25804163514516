import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute/PrivateRoute'

function RouteWithSubRoutes(route) {
  if (route.private) {
    return (
      <PrivateRoute
        path={route.path}
        authed={route.authenticated()}
        route={route}
      />
    )
  }
  
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  )
}

export default RouteWithSubRoutes
