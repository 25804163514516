import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { Breadcrumb } from 'react-breadcrumbs'

const PrivateRoute = ({ component: Component, authed, route, ...rest }) => {
  return (
    <Route
      {...rest}
      render={
        (props) => {
         return authed === true
          // ? <route.component {...props} routes={route.routes} />
          ? <BreadcrumbRoute {...props} component={route.component} route={route}  />
          : <Redirect to={{pathname: '/auth', prev: props.location}} />
        }
      }
    />
  )
}

const BreadcrumbRoute = ({ component: Component, route, ...props }) => {
  if (!route.title) {
    return <Component {...props} routes={route.routes} />
  }

  return (
    <Breadcrumb data={{
      title: route.title,
      pathname: route.computedMatch.url
    }}>
      <Component {...props} routes={route.routes} />
    </Breadcrumb>
  )
}

export default PrivateRoute
