import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from '../ProjectManualCreate/locale'
import moment from 'moment'
import './ProjectFilter.css'

registerLocale('ru', ru)

const ProjectFilter = ({ filters, onFilterChange }) => {

  const setArchiveChecked = (evt) => {
    updateFilters({ archive: evt.target.checked })
  }

  const onPeriodChange = (date) => {
    if (date) {
      date = moment(date).endOf('month').format('YYYY-MM-DD')
    }
    updateFilters({ periodEnd: date })
  }

  const updateFilters = (changedFilter) => {
    const newFilters = { ...filters, ...changedFilter }
    onFilterChange(newFilters)
  }

  const date = filters.periodEnd ? new Date(filters.periodEnd) : null

  return (
    <div className="project-filter">
      <div class="form-check project-filter-archive">
        <input class="form-check-input" type="checkbox" name="archive" id="archive" checked={filters.archive} onChange={setArchiveChecked}/>
        <label class="form-check-label" htmlFor="archive">
          архивные
        </label>
      </div>

      <DatePicker sm={4} locale='ru' placeholderText="Выберите период" selected={date} onChange={onPeriodChange} showMonthYearPicker dateFormat='MMMM yyyy' isClearable />
    </div>
  )
}

ProjectFilter.propTypes = {
  filters: PropTypes.shape({
    archive: PropTypes.bool.isRequired,
    periodEnd: PropTypes.string
  })
}

export default ProjectFilter