import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDom from 'react-dom'
import { Form, FormGroup, Label, Input, FormText, Row, Col, Button } from 'reactstrap'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from './locale'
import moment from 'moment'
import './ProjectManualCreate.css'

registerLocale('ru', ru)

class ProjectManualCreate extends Component {
  constructor(props) {
    super(props)

    this.currency = React.createRef()
    this.decimals = React.createRef()

    this.comment = React.createRef()
  }

  render() {
    const { dtsList, nfoList, reportTypeList, periodTypeList, currencyList, dtsVersionCode, nfoTypeCode, reportTypeCode, periodTypeCode, ep, periodEnd, isTemporary, onDtsChange, onNfoChange, onReportTypeChange, onPeriodTypeChange, onPeriodChange, onIsTemporaryChange, onCreate } = this.props

    const onDtsChange_ = (dts) => {
      onDtsChange(dts.code)
    }

    const onNfoChange_ = (nfoType) => {
      onNfoChange(nfoType.code)
    }

    const onReportTypeChange_ = (reportType) => {
      onReportTypeChange(reportType.code)
    }

    const onPeriodTypeChange_ = (periodType) => {
      onPeriodTypeChange(periodType.code)
    }

    const onPeriodChange_ = (date) => {
      switch (periodTypeCode) {
        case 'y':
          date = moment(date).endOf('year').format('YYYY-MM-DD')
        case 'q':
          date = moment(date).endOf('quarter').format('YYYY-MM-DD')
        case 'm':
          date = moment(date).endOf('month').format('YYYY-MM-DD')
        default:
          date = moment(date).format('YYYY-MM-DD')
        }
      onPeriodChange(date)
    }

    const onFormSubmit = (evt) => {
      evt.preventDefault()

      const defaultCurrency = ReactDom.findDOMNode(this.currency.current).value
      const defaultDecimals = parseInt(ReactDom.findDOMNode(this.decimals.current).value, 10)

      const comment = ReactDom.findDOMNode(this.comment.current).value

      onCreate(this.props.baseProjectId, dtsVersionCode, ep, periodEnd, defaultCurrency, defaultDecimals, comment, isTemporary)
    }

    // https://github.com/JedWatson/react-select/issues/2674#issuecomment-478596651
    const dtsVersionLabel = getEntityLabel(dtsVersionCode, dtsList) || "Версия таксономии"
    const nfoTypeLabel = getEntityLabel(nfoTypeCode, nfoList) || "Тип организации"
    const reportTypeLabel = getEntityLabel(reportTypeCode, reportTypeList) || "Тип отчетности"
    const periodTypeLabel = getEntityLabel(periodTypeCode, periodTypeList) || "Тип отчетного периода"

    const periodEndDate = periodEnd ? moment(periodEnd).toDate() : null

    return (
      <Form onSubmit={onFormSubmit}>
        <FormGroup>
          <Label for="dts">Версия таксономии</Label>
          <Select
            getOptionLabel={({ code, name }) => name}
            getOptionValue={({ code, name }) => code}
            value={{ code: dtsVersionCode, name: dtsVersionLabel }}
            options={dtsList}
            onChange={onDtsChange_}
          />
        </FormGroup>

        <FormGroup>
          <Label for="nfo">Тип организации</Label>
          <Select
            getOptionLabel={({ code, name }) => name}
            getOptionValue={({ code, name }) => code}
            value={{ code: nfoTypeCode, name: nfoTypeLabel }}
            options={nfoList}
            onChange={onNfoChange_}
          />
        </FormGroup>

        <FormGroup>
          <Label for="reportType">Тип отчетности</Label>
          <Select
            getOptionLabel={({ code, name }) => name}
            getOptionValue={({ code, name }) => code}
            value={{ code: reportTypeCode, name: reportTypeLabel }}
            options={reportTypeList}
            onChange={onReportTypeChange_}
          />
        </FormGroup>

        <FormGroup>
          <Label for="period">Тип отчетного периода</Label>
          <Select
            getOptionLabel={({ code, name }) => name}
            getOptionValue={({ code, name }) => code}
            value={{ code: periodTypeCode, name: periodTypeLabel }}
            options={periodTypeList}
            onChange={onPeriodTypeChange_}
          />
        </FormGroup>

        <FormGroup>
          <Label sm={2} for="period" className="period-label">Отчетный период</Label>
          { this.renderPeriodPicker(periodTypeCode, onPeriodChange_, periodEndDate) }
        </FormGroup>

        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="currency">Валюта по умолчанию</Label>
              <Input type="select" name="currency" id="currency" ref={this.currency}>
                {
                  currencyList.map((c) => <option value={c.code} selected={c.code === this.props.currency}>{c.name}</option>)
                }
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="decimals">Атрибут decimals по умолчанию</Label>
              <Input type="number" name="decimals" id="decimals" ref={this.decimals} defaultValue={this.props.decimals} className="project-decimals" />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for="comment">Комментарий</Label>
          <Input type="text" name="comment" id="comment" ref={this.comment} placeholder='Например, "Ответ на предписание" или "Тестовая сборка"' />
        </FormGroup>

        <FormGroup>
          <Label check className="project-is-temp-label">
            <Input type="checkbox" name="isTemporary" id="isTemporary" onClick={onIsTemporaryChange} />{' '}
            Временный отчет 
            <span className="project-is-temp-comment">(будет автоматически удален через 72 часа)</span>
          </Label>
        </FormGroup>

        <FormGroup>
          <Button>Создать отчет</Button>
        </FormGroup>
      </Form>
    )
  }

  renderPeriodPicker(periodType, onPeriodChange_, date) {
    if (!periodType) return null

    switch (periodType) {
      case 'y':
        return <DatePicker sm={4} locale='ru' placeholderText="Выберите период" selected={date} onChange={onPeriodChange_} showYearPicker dateFormat='yyyy год' />
      case 'q':
        return <DatePicker sm={4} locale='ru' placeholderText="Выберите период" selected={date} onChange={onPeriodChange_} showQuarterYearPicker dateFormat='QQ квартал yyyy' />
      case 'm':
        return <DatePicker sm={4} locale='ru' placeholderText="Выберите период" selected={date} onChange={onPeriodChange_} showMonthYearPicker dateFormat='MMMM yyyy' />
      default:
        return <DatePicker sm={4} locale='ru' placeholderText="Выберите период" selected={date} onChange={onPeriodChange_} dateFormat='dd.MM.yyyy' />
    }
  }
}

function getEntityLabel(code, list) {
  const item = list.find((item) => item.code === code)
  if (!item) return ''

  return item.name
}

ProjectManualCreate.propTypes = {
  dtsList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,

  nfoList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,

  reportTypeList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,

  periodTypeList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,

  currencyList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,

  baseProjectId: PropTypes.string,
  dtsVersionCode: PropTypes.string,
  nfoTypeCode: PropTypes.string,
  reportTypeCode: PropTypes.string,
  periodTypeCode: PropTypes.string,
  periodEnd: PropTypes.string,
  ep: PropTypes.string,
  currency: PropTypes.string,
  decimals: PropTypes.number,
  isTemporary: PropTypes.bool,

  onDtsChange: PropTypes.func.isRequired,
  onNfoChange: PropTypes.func.isRequired,
  onReportTypeChange: PropTypes.func.isRequired,
  onPeriodTypeChange: PropTypes.func.isRequired,
  onIsTemporaryChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
}

export default ProjectManualCreate
