import React from 'react'
import PropTypes from 'prop-types'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import LogIn from '../LogIn/LogIn'
import SignUp from '../SignUp/SignUp'
import './AuthForm.css'

const AuthForm = ({ isLogin, toggleLoginMode, onLogIn, onSignUp }) => {
  const activeTabId = 'login'
  // const activeTabId = isLogin ? 'login' : 'signup'
  return (
    <div className='auth-container'>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={ isLogin ? 'active' : '' }
            onClick={ () => toggleLoginMode(true) }
          >
            Вход
          </NavLink>
        </NavItem>
        {/*<NavItem>
          <NavLink
            className={ !isLogin ? 'active' : '' }
            onClick={ () => toggleLoginMode(false) }
          >
            Регистрация
          </NavLink>
        </NavItem>*/}
      </Nav>
      <TabContent activeTab={activeTabId}>
        <TabPane tabId='login'>
          <LogIn handler={onLogIn} />
        </TabPane>
        {/*<TabPane tabId='signup'>
          <SignUp handler={onSignUp} />
        </TabPane>*/}
      </TabContent>
    </div>
  )
}

AuthForm.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  toggleLoginMode: PropTypes.func.isRequired,
  onLogIn: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired
}

export default AuthForm
