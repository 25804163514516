import fetch from 'cross-fetch'
import * as types from './types'
import { anauthorize } from './user'

function requestGetProjects(page) {
	return {
		type: types.GET_PROJECTS_REQUEST,
		page
	}
}

function recieveGetProjects(projects) {
	return {
		type: types.GET_PROJECTS_SUCCESS,
		projects
	}
}

function failedGetProjects(err) {
	return {
		type: types.GET_PROJECTS_FAILURE,
		err
	}
}

export function getProjects(page = 0, offset = 0, filters = {}) {
	return dispatch => {
		const filter = Object.entries(filters).map((item) => `&${item[0]}=${item[1]}` )
		dispatch(requestGetProjects(page))
		return fetch(`/api/project/list?page=${page}&offset=${offset}${filter.join('')}`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveGetProjects(json)))
			.catch(err => dispatch(failedGetProjects(err)))
	}
}

export function changeFilterParams(page, offset, filters) {
	return dispatch => {
		dispatch({ type: types.PROJECTS_FILTERS_CHANGED, filters })
		dispatch(getProjects(page, offset, filters))
	}
}

function handleErrors(res, dispatch) {
	if (res.ok) {
		return res
	}

	if (res.status === 401) {
		dispatch(anauthorize())
	}

	throw Error(res.statusText)
}
