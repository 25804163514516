import fetch from 'cross-fetch'
import { push } from 'connected-react-router'

import * as types from './types'
import { anauthorize } from './user'
import { getView } from './project'
import  * as utils from './utils'

const debouncedSaveDraft = debounceSaveDraft(2000)

export function updateCell(okudId, tableId, rowIndex, cellId, data) {
  return dispatch => {
    const change = {
      type: 'updCell',
      tableId,
      rowIndex,
      cellId,
      value: data.value,
      decimals: data.decimals,
      currency: data.currency
    }
    dispatch(updateCell_(okudId, change))
    debouncedSaveDraft(okudId, change, dispatch)
    // saveDraft(projectId, [change], dispatch)
  }
}

// update redux state
export function updateCell_(okudId, change) {
  return {
    type: types.UPD_CELL,
    change
  }
}

export function addRow(okudId, tableId, rowIndex) {
  return dispatch => {
    const change = { type: 'addRow', tableId, rowIndex }
    dispatch(addRow_(okudId, change))
    debouncedSaveDraft(okudId, change, dispatch)
    // saveDraft(projectId, [change], dispatch)
  }
}

export function addRow_(okudId, change) {
  return {
    type: types.EDITOR_ADD_ROW,
    change
  }
}

export function removeRow(okudId, tableId, rowIndex) {
  return dispatch => {
    const change = { type: 'rmRow', tableId, rowIndex }
    dispatch(removeRow_(okudId, change))
    debouncedSaveDraft(okudId, change, dispatch)
    // saveDraft(projectId, [change], dispatch)
  }
}

export function removeRow_(okudId, change) {
  return {
    type: types.EDITOR_REMOVE_ROW,
    change
  }
}

export function copyRow(okudId, tableId, rowIndex) {
  return dispatch => {
    const change = { type: 'copyRow', tableId, rowIndex }
    dispatch(copyRow_(okudId, change))
    debouncedSaveDraft(okudId, change, dispatch)
    // saveDraft(projectId, [change], dispatch)
  }
}

export function copyRow_(okudId, change) {
  return {
    type: types.EDITOR_COPY_ROW,
    change
  }
}

function saveRequest() {
  return {
    type: types.SAVE_REQUEST
  }
}

function saveSuccess(changes) {
  return {
    type: types.SAVE_SUCCESS,
    changes
  }
}

function saveError(err) {
  return {
    type: types.SAVE_FAILURE,
    err
  }
}

// update data in db
export function saveDraft(okudId, changes, dispatch) {
  dispatch(saveRequest())

  const params = {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(changes)
  }

  return fetch(`/api/okud/${okudId}/edit`, params)
    .then(response => utils.handleErrors(response, dispatch))
    .then((response) => {
      dispatch(saveSuccess(changes))
    })
    .catch((err) => {
      dispatch(saveError(err))
    })
}


function debounceSaveDraft(ms, immediate) {
  let timeout
  let changes = []

  return function executedFunction(projectId, change, dispatch) {
    let context = this
    let args = arguments

    changes.push(change)
      
    let later = function() {
      timeout = null
      if (!immediate) {
        saveDraft.apply(context, [projectId, changes, dispatch])
        changes = []
      }
    }

    let callNow = immediate && !timeout;
  
    clearTimeout(timeout)

    timeout = setTimeout(later, ms)
  
    if (callNow) {
      saveDraft.apply(context, [projectId, changes, dispatch])
      changes = []
    }
  }
}

export function editorCancel() {
  return {
    type: types.EDITOR_CANCEL
  }
}

function draftApplyRequest() {
  return {
    type: types.DRAFT_APPLY_REQUEST
  }
}

function draftApplySuccess() {
  return {
    type: types.DRAFT_APPLY_SUCCESS
  }
}

function draftApplyError(err) {
  return {
    type: types.DRAFT_APPLY_FAILURE,
    err
  }
}

export function draftApply(projectId) {
  return dispatch => {
    dispatch(draftApplyRequest())

    return fetch(`/api/project/${projectId}/draft`, { method: 'POST' })
      .then(res => utils.handleErrors(res, dispatch))
      .then(res => dispatch(draftApplySuccess()))
      .then(res => dispatch(getView(projectId)))
      .then(res => dispatch(editorCancel()))
      .catch(err => dispatch(draftApplyError(err)))
  }
}

function draftDropRequest() {
  return {
    type: types.DRAFT_DROP_REQUEST
  }
}

function draftDropSuccess() {
  return {
    type: types.DRAFT_DROP_SUCCESS
  }
}

function draftDropError(err) {
  return {
    type: types.DRAFT_DROP_FAILURE,
    err
  }
}

export function draftDrop(projectId) {
  return dispatch => {
    dispatch(draftDropRequest())

    return fetch(`/api/project/${projectId}/draft`, { method: 'DELETE' })
      .then(res => utils.handleErrors(res, dispatch))
      .then(res => dispatch(draftDropSuccess()))
      .then(res => dispatch(editorCancel()))
      .catch(err => dispatch(draftDropError(err)))
  }
}
