import React from 'react'
import numeral from 'numeral'
import OnDemandSelect from '../../OnDemandSelect/OnDemandSelect'

numeral.localeData().delimiters.thousands = ' '

function formatNumeral(n, pattern, roundFn) {
  if (n.value() === null) return ''

  if (typeof roundFn === 'function') {
    return n.format(pattern, roundFn)
  }
  
  return n.format(pattern)
}

const NumericCell = ({ pattern, roundFn, currencyList }, {
  cell: { value: initialValue = { value: '' } },
  row: { index },
  column: { id },
  updateMyData,
}) => {

  const onFocus = (evt) => {
    // unformat value
    // todo: use widget to format value on fly
    evt.target.textContent = numeral(evt.target.textContent).value()

    let sel, range
    if (window.getSelection && document.createRange) {
        range = document.createRange()
        range.selectNodeContents(evt.target)
        sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
    } else if (document.body.createTextRange) {
        range = document.body.createTextRange()
        range.moveToElementText(evt.target)
        range.select()
    }

    if (evt.target.prevValue === undefined) {
      evt.target.prevValue = numeral(initialValue.value).value()
    }
  }

  const onBlur = (evt) => {
    const n = numeral(evt.target.textContent)
    let value = n.value()
    if (value !== null && typeof roundFn === 'function') {
      value = roundFn(value)
    }

    evt.target.textContent = formatNumeral(n, pattern, roundFn)

    if (evt.target.prevValue !== value) {
      evt.target.prevValue = value
      updateMyData(index, id, { value })
    }
  }

  const onDecimalsBlur = (evt) => {
    const decimals = evt.target.textContent
    if (evt.target.prevValue !== decimals) {
      evt.target.prevValue = decimals
      updateMyData(index, id, { decimals })
    }
  }

  const onKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      evt.target.blur()
    }
  }


  return (
    <div className="edit-cell">
      {
        currencyList && <OnDemandSelect
          list={currencyList}
          nameField={'name'}
          valueField={'code'}
          resultField={'currency'}
          initialValue ={initialValue.currency}
          onSelect={updateMyData.bind(this, index, id)}
          divModeClassName="edit-select edit-cell-currency"
          listModeClassName="edit-cell-currency"
        />
      }
      <div
        className="edit-cell-value"
        contentEditable 
        suppressContentEditableWarning 
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}>
          {formatNumeral(numeral(initialValue.value), pattern, roundFn)}
      </div>
      <div className="edit-cell-decimals-wrap">
        [
        <div
          className="edit-cell-decimals"
          contentEditable
          suppressContentEditableWarning
          tabindex='-1'
          onBlur={onDecimalsBlur}
          label='точность (decimals)'>
            {initialValue.decimals}
        </div>
        ]
      </div>
    </div>
  )
}

export default NumericCell
