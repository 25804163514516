import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import './ProjectUploadLog.css'

const ProjectUploadLog = ({ isOpen, onClose, uploads }) => {
  return (
    <Modal className="upload-errors-modal" isOpen={isOpen} toggle={onClose} centered={true} keyboard={true} size="lg">
      <ModalHeader toggle={onClose}>Ошибки импорта файлов</ModalHeader>
      <ModalBody>
        <Alert color="warning">
          Внимание! Найдены факты с ошибочными контекстами. Данные факты не будут импортированы.
          Если указанные факты нужны, необходимо исправить их контексты и импортировать измененные файлы заново.
        </Alert>

        {
          uploads.map((upload) => {
            return (
              <div className="upload-item">
                <div className="upload-item-name">{upload.filename}</div>

                <ul className="list-unstyled">
                  { 
                    upload.unusedFacts.map((fact) => <li>{fact}</li>)
                  }
                </ul>
              </div>
            )
          })
        }
      </ModalBody>
    </Modal>
  )
}

ProjectUploadLog.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,

  uploads: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    unusedFacts: PropTypes.array
  }))
}

export default ProjectUploadLog
