import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProjectReadOnlyManager from '../../components/ProjectReadOnlyManager/ProjectReadOnlyManager'
import ProjectActions from '../../components/ProjectActions/ProjectActions'
import ProjectInfoComp from '../../components/ProjectInfo/ProjectInfo'
import FileList from '../../components/FileList/FileList'
import ProjectUploadLog from '../../components/ProjectUploadLog/ProjectUploadLog'
import ProjectOkud from '../../components/ProjectOkud/ProjectOkud'
import {
  projectDownload,
  projectSetComment,
  projectSetReadOnly,
  setOkudCodeForAdd,
  resetOkudCodeForAdd,
  addOkud,
  setOkudIdForEdit,
  resetOkudIdForEdit,
  clearUploadErrors,
  removeFile,
  downloadFile,
  buildPackage,
  assignUser as assignUserToProject,
  unAssignUser as unAssignUserToProject,
  setRegInfo
} from '../../actions/project'
import {
  okudDownload,
  editOkud,
  resetOkud,
  removeOkud,
  assignUser as assignUserToOkud,
  unAssignUser as unAssignUserToOkud,
  setStatus
} from '../../actions/okud'
import { getUsers } from '../../actions/company'

class ProjectInfo extends Component {
  componentDidMount() {
    if (!this.props.users.length) {
      this.props.getUsers()
    }
  }

  render() {
    const {
      id,
      assigneeId,
      status,
      readOnly,
      dtsVersion,
      nfoType,
      reportType,
      ep,
      periodEnd,
      periodName,
      comment,
      projectDownload,
      okudDownload,
      projectSetComment,
      projectSetReadOnly,
      okudCodeForAdd,
      setOkudCodeForAdd,
      resetOkudCodeForAdd,
      addOkud,
      okudIdForEdit,
      setOkudIdForEdit,
      resetOkudIdForEdit,
      editOkud,
      resetOkud,
      removeOkud,
      struct,
      okuds,
      uploads,
      permissions,
      users,
      assignUserToProject,
      unAssignUserToProject,
      assignUserToOkud,
      unAssignUserToOkud,
      setStatus,
      regNum,
      regDate,
      regUrl,
      setRegInfo,
      clearUploadErrors,
      files,
      removeFile,
      downloadFile,
      buildPackage
    } = this.props

    const okudList = this._buildOkudList(struct, okuds)
    const uploadErrors = uploads.filter((upload) => upload.unusedFacts && upload.unusedFacts.length)
    const hasErrors = !!uploadErrors.length

    return (
      <div>
        <ProjectActions
          id={id}
          assigneeId={assigneeId}
          status={status}
          readOnly={readOnly}
          permissions={permissions}
          users={users}
          onDownload={projectDownload}
          onBuildPackage={buildPackage}
          assignUser={assignUserToProject}
          unAssignUser={unAssignUserToProject}
          regNum={regNum}
          regDate={regDate}
          regUrl={regUrl}
          setRegInfo={setRegInfo.bind(this, id)}
        />

        {
          hasErrors &&
          <ProjectUploadLog
            isOpen={hasErrors}
            uploads={uploadErrors}
            onClose={clearUploadErrors}
          />
        }

        <ProjectInfoComp
          ep={ep}
          dtsVersion={dtsVersion}
          nfoType={nfoType}
          reportType={reportType}
          periodEnd={periodEnd}
          periodName={periodName}
          comment={comment}
          onSetComment={projectSetComment.bind(this, id)}
        />

        <FileList
          projectId={id}
          files={files}
          onRemove={removeFile}
          onDownload={downloadFile}
        />

        <ProjectOkud
          id={id}
          okuds={okudList}
          users={users}
          periodEnd={periodEnd}
          okudCodeForAdd={okudCodeForAdd}
          setOkudCodeForAdd={setOkudCodeForAdd}
          resetOkudCodeForAdd={resetOkudCodeForAdd}
          addOkud={addOkud}
          okudIdForEdit={okudIdForEdit}
          setOkudIdForEdit={setOkudIdForEdit}
          resetOkudIdForEdit={resetOkudIdForEdit}
          editOkud={editOkud}
          resetOkud={resetOkud}
          removeOkud={removeOkud}
          onDownload={okudDownload}
          assignUser={assignUserToOkud}
          unAssignUser={unAssignUserToOkud}
          setStatus={setStatus}
        />
      </div>
    )
  }

  _buildOkudList(struct, okuds) {
    if (!struct) return []

    return struct.map((item) => {
      const list = okuds.filter((okud) => okud.code === item.code)
      return Object.assign({}, item, { list })
    })
  }
}

ProjectInfo.propTypes = {
  id: PropTypes.string.isRequired,
  assigneeId: PropTypes.string,
  status: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  ep: PropTypes.string.isRequired,
  dtsVersion: PropTypes.string.isRequired,
  nfoType: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  periodEnd: PropTypes.object.isRequired,
  periodName: PropTypes.string.isRequired,
  comment: PropTypes.string,

  buildPackage: PropTypes.func.isRequired,
  projectDownload: PropTypes.func.isRequired,
  okudDownload: PropTypes.func.isRequired,
  projectSetReadOnly: PropTypes.func.isRequired,
  
  okudCodeForAdd: PropTypes.string.isRequired,
  setCurrentOkud: PropTypes.func.isRequired,
  resetCurrentOkud: PropTypes.func.isRequired,
  okudIdForEdit: PropTypes.string.isRequired,
  setOkudIdForEdit: PropTypes.func.isRequired,
  resetOkudIdForEdit: PropTypes.func.isRequired,

  addOkud: PropTypes.func.isRequired,
  editOkud: PropTypes.func.isRequired,
  resetOkud: PropTypes.func.isRequired,
  removeOkud: PropTypes.func.isRequired,

  struct: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    label: PropTypes.string,
    aspects: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }))
  })).isRequired,

  okuds: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired
  })).isRequired,

  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),

  uploads: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    unusedFacts: PropTypes.array
  })),
  clearUploadErrors: PropTypes.func.isRequired,

  permissions: PropTypes.object.isRequired,

  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  getUsers: PropTypes.func.isRequired,
  assignUserToProject: PropTypes.func.isRequired,
  unAssignUserToProject: PropTypes.func.isRequired,
  assignUserToOkud: PropTypes.func.isRequired,
  unAssignUserToOkud: PropTypes.func.isRequired,

  setStatus: PropTypes.func.isRequired,

  regNum: PropTypes.string,
  regDate: PropTypes.instanceOf(Date),
  regUrl: PropTypes.string,

  removeFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
  return {
    id: state.project.id,
    assigneeId: state.project.assigneeId,
    status: state.project.status,
    comment: state.project.comment,
    regNum: state.project.regNum,
    regDate: state.project.regDate,
    regUrl: state.project.regUrl,
    readOnly: state.project.readOnly,
    struct: state.project.struct,
    okuds: state.project.okuds,
    files: state.project.files,
    uploads: state.project.uploads,
    permissions: state.project.permissions,
    users: state.company.users,
    okudCodeForAdd: state.project.okudCodeForAdd,
    okudIdForEdit: state.project.okudIdForEdit,
    ...state.project.info
  }
}

export default connect(mapStateToProps, {
  projectDownload,
  okudDownload,
  addOkud,
  editOkud,
  resetOkud,
  removeOkud,
  projectSetComment,
  projectSetReadOnly,
  setOkudCodeForAdd,
  resetOkudCodeForAdd,
  setOkudIdForEdit,
  resetOkudIdForEdit,
  clearUploadErrors,
  getUsers,
  assignUserToProject,
  unAssignUserToProject,
  assignUserToOkud,
  unAssignUserToOkud,
  setStatus,
  setRegInfo,
  removeFile,
  downloadFile,
  buildPackage
})(ProjectInfo)
