import { anauthorize } from './user'

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

export function getFileNameFromResponse(res) {
	const disposition = res.headers.get('content-disposition')
	const matches = filenameRegex.exec(disposition)
	if (matches != null && matches[1]) { 
		return matches[1].replace(/['"]/g, '')
	}

	return 'result.xml'
}

export async function handleErrors(res, dispatch) {
	if (res.ok) {
		return res
	}

	if (res.status === 401) {
		dispatch(anauthorize())
	}

	const text = await res.text()
	throw Error(text)
}
