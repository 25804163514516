import React from 'react'
import PropTypes from 'prop-types'
import './OkudStatus.css'

const OkudStatus = ({ status, canFinish, canRevert, setStatus }) => {
  const setInprogressStatus = setStatus.bind(this, 'inprogress')
  const setDoneStatus = setStatus.bind(this, 'done')

  let statusLabel
  let statusAction

  if (status  === 'inprogress') {

    statusLabel = <span className="okud-status-label text-warning">В работе</span>
    if (canFinish) {
      statusAction = <span className="pseudo-link" onClick={setDoneStatus}>Завершить работу</span>
    }

  } else if (status  === 'done') {

    statusLabel = <span className="okud-status-label text-success">Сделано</span>
    if (canRevert) {
      statusAction = <span className="pseudo-link" onClick={setInprogressStatus}>Вернуть на доработку</span>
    }

  }

  return (
    <div className="okud-status">
      {statusLabel}
      {statusAction}
    </div>
  )
}

OkudStatus.propTypes = {
  status: PropTypes.string,
  canFinish: PropTypes.bool.isRequired,
  canRevert: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired
}

export default OkudStatus
