import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logIn, toggleLoginMode, signUp } from '../../actions/user'
import AuthForm from '../../components/AuthForm/AuthForm'

class Auth extends Component {
  async signUp() {
    await this.props.signUp(...arguments)
  }

  async logIn() {
    const prevLocation = this.props.history.location.prev
    const originUrl = prevLocation ? prevLocation.pathname : '/'
    await this.props.logIn(...arguments, originUrl)
  }

  render() {
    const { isLogin, toggleLoginMode } = this.props

    return (
      <AuthForm
        isLogin={isLogin}
        toggleLoginMode={toggleLoginMode}
        onLogIn={this.logIn.bind(this)}
        onSignUp={this.signUp.bind(this)}
      />
    )
  }
}

Auth.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  logIn: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  toggleLoginMode: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    isLogin: state.user.isLogin
  }
}

export default connect(mapStateToProps, { logIn, signUp, toggleLoginMode })(Auth)
