import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledAlert } from 'reactstrap'
import './ErrorAlert.css'

class ErrorAlert extends Component {
  render() {
    const { error } = this.props
    return (
      <div className="container">
        {
          error &&
          <UncontrolledAlert className="error-allert" color="danger">{error}</UncontrolledAlert>
        }
      </div>
    )
  }
}

ErrorAlert.propTypes = {
  error: PropTypes.string
}

export default ErrorAlert
