import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { FileUpload } from 'redux-file-upload'
import './FileList.css'

const FileList = ({ projectId, files, onDownload, onRemove }) => {
  return (
    <div className="project-section project-files">
      <h5>Сопроводительные документы</h5>
      <ul className="list-inline file-list">
        {
          files.map((file) => {
            return (
              <li class="list-inline-item file-item">
                <span className="pseudo-link file-item-link" onClick={onDownload.bind(this, projectId, file.id)}>{file.name || 'test'}</span>
                <i class="fas fa-times project-file-rm" title="Удалить файл" onClick={onRemove.bind(this, projectId, file.id)}></i>
              </li>
            )
          })
        }
      </ul>
      
      <div className="file-upload-zone">
        <FileUpload
          multiple={true}
          dropzoneId="dropZone"
          url={`/api/project/${projectId}/file/upload`}
        >
          <NavLink to='#'>Загрузить файл</NavLink>
        </FileUpload>
      </div>
      
    </div>
  )
}

FileList.propTypes = {
  projectId: PropTypes.string.isRequired,

  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),

  onDownload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default FileList
