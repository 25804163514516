import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDom from 'react-dom'
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap'
import './LogIn.css'

class LogIn extends Component {
  constructor(props) {
    super(props)

    this.email = React.createRef()
    this.password = React.createRef()
  }

  handleSubmit(evt) {
    evt.preventDefault()

    const email = ReactDom.findDOMNode(this.email.current).value
    const password = ReactDom.findDOMNode(this.password.current).value

    this.props.handler(email, password)
  }

  render() {
    return (
      <div>
        <Form className="form-login" onSubmit={this.handleSubmit.bind(this)}>
          <h2>Вход</h2>
          <FormGroup>
            <Label for="email">Email адрес</Label>
            <Input type="email" name="email" id="email" ref={this.email} placeholder="user@company.ru" />
          </FormGroup>
          <FormGroup>
            <Label for="password">Пароль</Label>
            <Input type="password" name="password" id="password" ref={this.password} placeholder="*******" />
          </FormGroup>
          <Button color="primary">Войти</Button>
        </Form>
      </div>
    )
  }
}

LogIn.propTypes = {
  handler: PropTypes.func.isRequired
}

export default LogIn
