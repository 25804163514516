import { combineReducers } from 'redux'
import produce from 'immer'
import { LOCATION_CHANGE } from 'connected-react-router'
import * as types from '../actions/types'

const defaultState = {
  list: [],
  page: 0,
  offset: 0,
  filters: {
    archive: false,
    periodEnd: null
  }
}

export default function (state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PROJECTS_SUCCESS:
        action.projects.forEach((p) => draft.list.push(p))
        draft.page++
        break

      case types.CREATE_PROJECT_SUCCESS:
      case types.FILE_UPLOAD_COMPLETE:
      	draft.list.splice(0, draft.list.length)
        draft.page = 0
        draft.offset = 0
      	break

      case types.PROJECT_REMOVE_SUCCESS:
      case types.PROJECT_ARCHIVE_SUCCESS:
      case types.PROJECT_RESTORE_SUCCESS:
        const index = draft.list.findIndex((p) => p.id === action.id)
        if (index > -1) {
          draft.list.splice(index, 1)
          draft.offset--
        }
        break

      case types.PROJECTS_FILTERS_CHANGED:
        draft.list = []
        draft.filters = { ...draft.filters, ...action.filters}
        draft.page = 0
        draft.offset = 0
        break

      case types.LOGOUT_USER_SUCCESS:
        return defaultState
      
      default:
        break
    }
  })
}
