import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Step from '../Step/Step'
import './Steps.css'

class Steps extends Component {
  render() {
    const items = this.props.steps.map((step, i) => {
      return <Step
        name={ step.name }
        num={ i + 1 }
        key={ i + 1 }
        href={ this.props.pathname + step.href }
        isActive={ i === this.props.activeIndex }
      />
    })

    return (
      <div className="container steps">
        <div className="row">
          { items }
        </div>
      </div>
    )
  }
}

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  })).isRequired,
  activeIndex: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired
}

export default Steps
