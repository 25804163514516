import { combineReducers } from 'redux'
import * as types from '../actions/types'

const activeIndex = (state = 0, action) => {
  switch (action.type) {
    case types.STEP_GOTO:
      return action.index
    case types.STEP_NEXT:
      return state + 1
    case types.STEP_PREV:
      return state - 1
  }

  return state
}

const steps = (
  state = [
    { name: 'Документы', href: '/' },
    { name: 'Проверка', href: '/check' },
    { name: 'Просмотр', href: '/view' },
    { name: 'Результат', href: '/result' }
  ], action) => {
  return state
}

const wizardReducer = combineReducers({
  activeIndex,
  steps
})

export default wizardReducer
