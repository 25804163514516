import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loader from './loader'
import user from './user'
import company from './company'
import project from './project'
import okud from './okud'
import projects from './projects'
import newp from './newp'
import wizard from './wizard'

const rootReducer = (history) =>  combineReducers({
	router: connectRouter(history),
	loader,
	user,
	company,
	project,
	okud,
	projects,
	newp,
	wizard
})

export default rootReducer
