import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { Formik, Field } from 'formik'

const UserInviteModal = ({ isOpen, onClose, onInvite }) => {
  const onSubmit = (values) => {
    onInvite(values.name, values.email)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true} keyboard={true}>
      <ModalHeader toggle={onClose}/>
      <ModalBody>
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Имя</Label>
                <Input tag={Field} type="text" name="name" placeholder="Например, Иванов Иван" />
              </FormGroup>
              <FormGroup>
                <Label for="email">Почта</Label>
                <Input tag={Field} type="email" name="email" placeholder="Почта" />
              </FormGroup>
              <Button color="primary">Создать</Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

UserInviteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired
}

export default UserInviteModal
