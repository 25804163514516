import React from "react"
import { useField, useFormikContext } from "formik"
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from "date-fns/locale/ru"

registerLocale('ru', ru)

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  // Без этого преобразования DatePicker неправильно форматирует исходную дату
  field.value = field.value ? new Date(field.value) : null

  return (
    <DatePicker
      {...field}
      {...props}
      selected={field.value ? new Date(field.value) : null}
      onChange={val => {
        setFieldValue(field.name, val)
      }}
    />
  )
}

export default DatePickerField