import Auth from './containers/Auth/Auth'
import ResetPassword from './containers/ResetPassword/ResetPassword'

import ProjectList from './containers/ProjectList/ProjectList'
import ProjectCreate from './containers/ProjectCreate/ProjectCreate'
import Project from './containers/Project/Project'
import ProjectInfo from './containers/ProjectInfo/ProjectInfo'
import ProjectCheck from './containers/ProjectCheck/ProjectCheck'
import ProjectEdit from './containers/ProjectEdit/ProjectEdit'

import Okud from './containers/Okud/Okud'
import OkudEdit from './containers/OkudEdit/OkudEdit'
import OkudCheck from './containers/OkudCheck/OkudCheck'

import Company from './containers/Company/Company'

const routes = [
	{
		path: '/auth/reset/:token',
		component: ResetPassword
	},
	{
		path: '/auth',
		component: Auth
	},
	{
		path: '/company',
		component: Company,
		private: true
	},
	{
		path: '/project/list',
		component: ProjectList,
		title: 'Список отчетов',
		private: true
	},
	{
		path: '/project/new',
		component: ProjectCreate,
		private: true
	},
	{
		path: '/project/:projectId',
		component: Project,
		title: 'Отчет',
		private: true,
		routes: [
			{
				path: '/project/:projectId/check',
				component: ProjectCheck,
				title: 'Проверка отчета',
				private: true
			},
			{
				path: '/project/:projectId/okud/:okudId/view',
				component: OkudEdit,
				title: 'Редактирование отчетной формы',
				private: true
			},
			{
				path: '/project/:projectId/okud/:okudId/check',
				component: OkudCheck,
				title: 'Проверка отчетной формы',
				private: true
			},
			{
				path: '/project/:projectId/',
				component: ProjectInfo,
				private: true
			}
		]
	},
	{
		path: '/',
		component: ProjectList,
		private: true
	},
]

export default routes
