import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { createProject } from '../../actions/project'
import { logOut } from '../../actions/user'
import { changeViewTable } from '../../actions/editor'
import { Row, Col } from 'reactstrap'
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes/RouteWithSubRoutes'
import Navbar from '../../components/Navbar/Navbar'
import Loader from '../../components/Loader/Loader'
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert'
import ProjectList from '../ProjectList/ProjectList'
import ProjectCreate from '../ProjectCreate/ProjectCreate'
import Project from '../Project/Project'
import Auth from '../Auth/Auth'
import routes from '../../routes'

class Main extends Component {
  render() {
    const { authenticated, loader, createProject, logOut } = this.props

    const isLoading = loader.requests.length
    const loaderMsg = isLoading ? loader.requests[loader.requests.length - 1].msg : ''

    return (
      <div>
        <Navbar userName="User" authenticated={authenticated} onNewProject={createProject} onLogOut={logOut} />
        <Loader label={loaderMsg} active={isLoading} hideBackdrop={loader.hideBackdrop} />
        <ErrorAlert error={loader.error} />

        <div className="container">
          <Switch>
            {
              routes.map((route, i) => (
                <RouteWithSubRoutes key={i} authenticated={() => authenticated} {...route} />
              ))
            }
          </Switch>
        </div>
      </div>
    )
  }
}



Project.propTypes = {
  authenticated: PropTypes.boolean,
  loader: PropTypes.object,
  createProject: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    authenticated: state.user.authenticated,
    loader: state.loader
  }
}

export default connect(mapStateToProps, { createProject, logOut })(Main)
