import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ru'
import Moment from 'react-moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import ProjectStatus from '../ProjectStatus/ProjectStatus'
import './ProjectList.css'

class ProjectList extends Component {
  render() {
    const { projects, fetchPage, removeProject, archiveProject, restoreProject } = this.props

    if (!projects.length) {
      return <h5 style={{"text-align": "center"}}>У вас нет ни одного отчета</h5>
    }

    const groups = this._groupProjects(projects)

    const onRemoveClick = (id, evt) => {
      if (window.confirm('Пожалуйста, подтвердите удаление отчета')) {
        removeProject(id)
      }
    }

    const onArchiveClick = (id, evt) => {
      if (window.confirm('Пожалуйста, подтвердите архивирование отчета')) {
        archiveProject(id)
      }
    }

    const onRestoreClick = (id, evt) => {
      if (window.confirm('Пожалуйста, подтвердите восстановление отчета')) {
        restoreProject(id)
      }
    }

    return (
      <div className="project-list">
        <InfiniteScroll
          dataLength={projects.length}
          next={fetchPage}
          hasMore={true}
        >
          {
            groups.map((group) => this._renderGroup(group, onRemoveClick, onArchiveClick, onRestoreClick))
          }
        </InfiniteScroll>
      </div>
    )
  }

  _groupProjects(projects) {
    return projects.reduce((groups, project) => {
      if (!project.info) return groups

      const key = moment(project.info.periodEnd).endOf('month').format('YYYY-MM-DD')
      let group =  groups.find((g) => g.key === key)
      if (!group) {
        group = { key, projects: [] }
        groups.push(group)
      }
      group.projects.push(project)

      return groups
    }, [])
  }

  _renderGroup(group, onRemoveClick, onArchiveClick, onRestoreClick) {
    return (
      <div className="project-list-period">
        <h5>
          <Moment format="MMMM YYYY" locale="ru">{group.key}</Moment>
        </h5>
        <ListGroup>
        {
          group.projects.map((project) => {
            const { id, info, aspects, err } = project

            return (
              <ListGroupItem key={id}>
                <div className="project-status-placeholder">
                  <ProjectStatus
                    status={project.status}
                    regNum={project.regNum}
                    regDate={project.regDate}
                    regUrl={project.regUrl}
                  />
                </div>

                {
                  info &&
                  <div>
                    <div>
                      <NavLink to={`/project/${id}`}>{info.reportType}</NavLink>
                    </div>

                    <div className="project-item-params">
                      <span>[{info.periodName}]</span>
                      <span>[{info.dtsVersion}]</span>
                      <span>[{info.nfoTypeCode}]</span>
                      <span>[{info.reportTypeCode}]</span>
                      <span>[{info.periodTypeCode}]</span>
                    </div>

                    <dl className="project-item-aspects">
                      {
                        aspects.map((aspect) => {
                          return (
                            <span>
                              <dt>{aspect.name}</dt>
                              <dd>{aspect.values.filter(Boolean).join(', ')}</dd>
                            </span>
                          )
                        })
                      }
                    </dl>
                    
                    <div className="project-item-comment">{info.comment}</div>

                    <div>
                      <dl className="project-item-assignee">
                        <dt>Исполнитель</dt>
                        <dd>{project.assigneeName || 'Не назначен'}</dd>
                      </dl>

                      {
                        project.permissions.canArchive && !project.isArchived &&
                        <div className="project-item-archive">
                          <NavLink to='#' className="project-item-arch-link" onClick={onArchiveClick.bind(this, id)}>архивировать</NavLink>
                        </div>
                      }

                      {
                        project.permissions.canRemove && project.isArchived &&
                        <>
                          <div className="project-item-remove">
                            <NavLink to='#' className="project-item-rm-link" onClick={onRemoveClick.bind(this, id)}>удалить</NavLink>
                          </div>

                          <div className="project-item-restore">
                            <NavLink to='#' className="project-item-restore-link" onClick={onRestoreClick.bind(this, id)}>восстановить</NavLink>
                          </div>
                        </>
                      }

                    </div>
                  </div>
                }

                {
                  err &&
                  <div className="project-item-err">{`Не удалось загрузить отчет ${id}. Ошибка: ${err}`}</div>
                }

              </ListGroupItem>
            )
          })
        }
        </ListGroup>
      </div>
    )
  }
}

ProjectList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    assigneeId: PropTypes.string,
    assigneeName: PropTypes.string,
    info: PropTypes.object,
    aspects: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string)
    })),
    permissions: PropTypes.object,
    err: PropTypes.string
  })).isRequired,

  fetchPage: PropTypes.func.isRequired,
  removeProject: PropTypes.func.isRequired,
  archiveProject: PropTypes.func.isRequired,
  restoreProject: PropTypes.func.isRequired
}

export default ProjectList
