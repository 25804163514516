import './CompanyFundList.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'reactstrap'
import moment from 'moment'
import EditFundModal from '../EditFundModal/EditFundModal'


const CompanyFundList = ({ funds, fundTypes, onAddFund, onEditFund, onDeleteFund }) => {
  const [editFundModalState, setEditFundModalOpen] = useState({isOpen: false, fund: null})

  const onAddFundButtonClick = () => setEditFundModalOpen({isOpen: true, fund: null})
  const onEditFundButtonClick = (fund) => setEditFundModalOpen({isOpen: true, fund: fund})
  const onDeleteFundButtonClick = (id) => {
    if (window.confirm('Пожалуйста, подтвердите удаление фонда')) {
      onDeleteFund(id)
    }
  }

  return (
    <div className="group company-fund-list">
      <div className="group-header">
        Фонды
        <Button className="float-right" size="sm" onClick={() => onAddFundButtonClick()}>Зарегистрировать фонд</Button>
      </div>
      <div className="group-body">
        <Table hover>
          <thead>
            <tr>
              <th>Название</th>
              <th>Тип</th>
              <th class="is-kval">Только для квал. инвесторов</th>
              <th>Дата прекращения</th>
              <th>Идентификатор для ЦБ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              funds.map((fund) => {
                return (
                  <tr>
                    <td>
                      <em onClick={() => onEditFundButtonClick(fund)}>
                        {fund.name}
                      </em>
                    </td>
                    <td>
                      {fundTypes.filter(type => type.code === fund.type)[0].name}
                    </td>
                    <td>
                      {fund.isKval ? 'Да' : 'Нет'}
                    </td>
                    <td>
                      {fund.cancelDate? moment(fund.cancelDate).format('DD.MM.YYYY') : ''}
                    </td>
                    <td>
                      {fund.aspectValue}
                    </td>
                    <td><i class="fas fa-times btn-delete" onClick={() => onDeleteFundButtonClick(fund._id)}></i></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>

      <EditFundModal
        isOpen={editFundModalState.isOpen}
        fund={editFundModalState.fund}
        onClose={() => setEditFundModalOpen(false)}
        fundTypes={fundTypes}
        onAddFund={onAddFund}
        onEditFund={onEditFund} />
    </div>
  )
}

CompanyFundList.propTypes = {
  funds: PropTypes.arrayOf(PropTypes.object).isRequired,
  fundTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddFund: PropTypes.func.isRequired,
  onEditFund: PropTypes.func.isRequired,
  onDeleteFund: PropTypes.func.isRequired
}

export default CompanyFundList