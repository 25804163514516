import React, { useState } from 'react'
import OnDemandMultiSelect from '../../OnDemandSelect/OnDemandMultiSelect'

const EnumCellMultiSelect = (list, {
  cell: { value: initialValue =  { value: [] } },
  row: { index },
  column: { id },
  updateMyData
}) => {
  return (
    <OnDemandMultiSelect
      list={list}
      nameField={'value'}
      valueField={'key'}
      resultField={'value'}
      initialValue ={initialValue.value}
      onSelect={updateMyData.bind(this, index, id)}
      divModeClassName="edit-cell edit-cell-value-list"
      listModeClassName="edit-select edit-cell-value"
    />
  )
}

export default EnumCellMultiSelect
