import fetch from 'cross-fetch'
import { push } from 'connected-react-router'
import * as types from './types'

function beginLogin() {
  return { type: types.LOGIN_USER_REQUEST }
}

function loginSuccess() {
  return {
    type: types.LOGIN_USER_SUCCESS
  }
}

function loginError(err) {
  return {
    type: types.LOGIN_USER_FAILURE,
    err
  }
}

function beginSignUp() {
  return { type: types.SIGNUP_USER_REQUEST }
}

function signUpSuccess() {
  return {
    type: types.SIGNUP_USER_SUCCESS
  }
}

function signUpError(err) {
  return {
    type: types.SIGNUP_USER_FAILURE,
    err
  }
}

function beginLogout() {
  return {
    type: types.LOGOUT_USER_REQUEST
  }
}

function logoutSuccess() {
  return {
    type: types.LOGOUT_USER_SUCCESS
  }
}

function logoutError(err) {
  return {
    type: types.LOGOUT_USER_FAILURE,
    err
  }
}

function beginReset() {
  return {
    type: types.USER_RESET_REQUEST
  }
}

function resetSuccess() {
  return {
    type: types.USER_RESET_SUCCESS
  }
}

function resetError(err) {
  return {
    type: types.USER_RESET_FAILURE,
    err
  }
}

export function toggleLoginMode() {
  return {
    type: types.TOGGLE_LOGIN_MODE
  }
}

export function anauthorize() {
  return {
    type: types.USER_ANAUTHORIZED
  }
}

export function logIn(email, password, originUrl) {
  return dispatch => {
    dispatch(beginLogin())

    const params = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    }

    return fetch('/api/auth/login', params)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText)
        
        dispatch(loginSuccess())
        dispatch(push(originUrl || '/project/list'))
      })
      .catch((err) => {
        dispatch(loginError(err))
      })
  }
}

export function signUp(name, email, password, identifier) {
  return dispatch => {
    dispatch(beginSignUp());

    const params = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email, password, identifier })
    }

    return fetch('/api/auth/signup', params)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText)

        dispatch(signUpSuccess());
        dispatch(push('/project/list'))
      })
      .catch((err) => {
        dispatch(signUpError(err));
      });
  };
}

export function logOut() {
  return (dispatch) => {
    dispatch(beginLogout());

    return fetch('/api/auth/logout', { method: 'POST' })
      .then((response) => {
          dispatch(logoutSuccess());
          dispatch(push('/auth'))
      })
      .catch((err) => {
        dispatch(logoutError(err));
      });
  };
}

export function reset(password, token) {
  return dispatch => {
    dispatch(beginReset())

    const params = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password, token })
    }

    return fetch('/api/auth/reset', params)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText)
        
        dispatch(resetSuccess())
        dispatch(push('/project/list'))
      })
      .catch((err) => {
        dispatch(resetError(err))
      })
  }
}
