import fetch from 'cross-fetch'
import { push } from 'connected-react-router'

import * as types from './types'
import { editorStart } from './editor'
import { getNfoList, getEpList } from './ui'
import * as utils from './utils'

function requestCreateProject() {
	return {
		type: types.CREATE_PROJECT_REQUEST
	}
}

function recieveCreateProject(project) {
	return {
		type: types.CREATE_PROJECT_SUCCESS,
		id: project.id
	}
}

function failedCreateProject(err) {
	return {
		type: types.CREATE_PROJECT_FAILURE,
		err
	}
}

export function createProject(baseProjectId, dtsVersion, ep, periodEnd, defaultCurrency, defaultDecimals, comment, isTemporary) {

	const params = {
		method: 'POST',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			baseProjectId,
			dtsVersion,
			ep,
			periodEnd,
			defaultCurrency,
			defaultDecimals,
			comment,
			isTemporary
		})
	  }

	return dispatch => {
		dispatch(requestCreateProject())
		return fetch('/api/project', params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveCreateProject(json)))
			.then(json => dispatch(changeProject(json.id)))
			.then(json => dispatch(push(`/project/${json.id}`)))
			.catch(err => dispatch(failedCreateProject(err)))
	}
}

function requestAddOkud() {
	return {
		type: types.OKUD_ADD_REQUEST
	}
}

function recieveAddOkud(okud) {
	return {
		type: types.OKUD_ADD_SUCCESS,
		okud
	}
}

function failedAddOkud(err) {
	return {
		type: types.OKUD_ADD_FAILURE,
		err
	}
}

export function addOkud(id, okud) {
	const params = {
		method: 'POST',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify(okud)
	}

	return dispatch => {
		dispatch(requestAddOkud())
		return fetch(`/api/project/${id}/okud/add`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveAddOkud(json)))
			.catch(err => dispatch(failedAddOkud(err)))
	}
}

export function selectBaseProject({ id, info }) {
	return dispatch => {
		dispatch(getEpList(info.dtsVersionCode))
			.then(a => dispatch({
				type: types.SELECT_BASE_PROJECT,
				id,
				info
			})
		)
	}
}

function requestGetProject() {
	return {
		type: types.GET_PROJECT_REQUEST
	}
}

function recieveGetProject(project) {
	return {
		type: types.GET_PROJECT_SUCCESS,
		project
	}
}

function failedGetProject(err) {
	return {
		type: types.GET_PROJECT_FAILURE,
		err
	}
}

export function getProject(id) {
	return dispatch => {
		dispatch(requestGetProject())
		return fetch(`/api/project/${id}`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveGetProject(json)))
			.catch(err => dispatch(failedGetProject(err)))
	}
}

export function changeProject(id) {
	return {
		type: types.CHANGE_PROJECT,
		id
	}
}

function requestCbrCheckProject() {
	return {
		type: types.CBR_CHECK_REQUEST
	}
}

function recieveCbrCheckProject(res) {
	return {
		type: types.CBR_CHECK_SUCCESS,
		cbrCheckLog: res.cbrCheckLog
	}
}

function failedCbrCheckProject(err) {
	return {
		type: types.CBR_CHECK_FAILURE,
		err
	}
}

export function cbrCheckProject(id) {
	return dispatch => {
		dispatch(requestCbrCheckProject())
		return fetch(`/api/project/${id}/check`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveCbrCheckProject(json)))
			.catch(err => dispatch(failedCbrCheckProject(err)))
	}
}

function requestFixProject() {
	return {
		type: types.FIX_REQUEST
	}
}

function recieveFixProject(res) {
	return {
		type: types.FIX_SUCCESS
	}
}

function failedFixProject(err) {
	return {
		type: types.FIX_FAILURE,
		err
	}
}

export function fixProject(id) {
	return dispatch => {
		dispatch(requestFixProject())
		return fetch(`/api/project/${id}/fix`, { method: 'PUT' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(json => {
				dispatch(recieveFixProject(json))
				dispatch(getProject(id))
			})
			.catch(err => dispatch(failedFixProject(err)))
	}
}

function requestView(projectId) {
	return {
		type: types.VIEW_REQUEST,
		id: projectId
	}
}

function recieveView(res) {
	return {
		type: types.VIEW_SUCCESS,
		view: res.view
	}
}

function failedView(err) {
	return {
		type: types.VIEW_FAILURE,
		err
	}
}

export function getView(projectId) {
	return dispatch => {
		dispatch(requestView(projectId))
		return fetch(`/api/project/${projectId}/view`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveView(json)))
			.catch(err => dispatch(failedView(err)))
	}
}

function requestFormulaCheckProject() {
	return {
		type: types.FORMULA_CHECK_REQUEST
	}
}

function recieveFormulaCheckProject(res) {
	return {
		type: types.FORMULA_CHECK_SUCCESS,
		formulaCheckLog: res.formulaCheckLog
	}
}

function failedFormulaCheckProject(err) {
	return {
		type: types.FORMULA_CHECK_FAILURE,
		err
	}
}

export function formulaCheckProject(id) {
	return dispatch => {
		dispatch(requestFormulaCheckProject())
		return fetch(`/api/project/${id}/formula`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveFormulaCheckProject(json)))
			.catch(err => dispatch(failedFormulaCheckProject(err)))
	}
}

function requestRemoveFile() {
	return {
		type: types.FILE_REMOVE_REQUEST
	}
}

function recieveRemoveFile(fileId) {
	return {
		type: types.FILE_REMOVE_SUCCESS,
		id: fileId
	}
}

function failedRemoveFile(err) {
	return {
		type: types.FILE_REMOVE_FAILURE,
		err
	}
}

export function removeFile(projectId, fileId) {
	const params = {
		method: 'DELETE',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	}

	return dispatch => {
		dispatch(requestRemoveFile())
		return fetch(`/api/project/${projectId}/file/${fileId}`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => dispatch(recieveRemoveFile(fileId)))
			.catch(err => dispatch(failedRemoveFile(err)))
	}
}

function requestFileDownload() {
	return {
		type: types.FILE_DOWNLOAD_REQUEST
	}
}

function recieveFileDownload(project) {
	return {
		type: types.FILE_DOWNLOAD_SUCCESS
	}
}

function failedFileDownload(err) {
	return {
		type: types.FILE_DOWNLOAD_FAILURE,
		err
	}
}

export function downloadFile(projectId, fileId) {
	return dispatch => {
		dispatch(requestFileDownload())
		return fetch(`/api/project/${projectId}/file/${fileId}`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => {
				const link = document.createElement('a')

				const url = window.URL.createObjectURL(res._bodyBlob)
				link.href = url

				const filename = utils.getFileNameFromResponse(res)
				link.setAttribute('download', filename)

				document.body.appendChild(link)
				link.click()

				dispatch(recieveFileDownload())
			})
			.catch(err => dispatch(failedFileDownload(err)))
	}
}

function requestBuildPackage() {
	return {
		type: types.BUILD_PACKAGE_REQUEST
	}
}

function recieveBuildPackage() {
	return {
		type: types.BUILD_PACKAGE_SUCCESS
	}
}

function failedBuildPackage(err) {
	return {
		type: types.BUILD_PACKAGE_FAILURE,
		err
	}
}

export function buildPackage(id) {
	return dispatch => {
		dispatch(requestBuildPackage())
		return fetch(`/api/project/${id}/package`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => {
				const link = document.createElement('a')

				const url = window.URL.createObjectURL(res._bodyBlob)
				link.href = url

				const filename = utils.getFileNameFromResponse(res)
				link.setAttribute('download', filename)

				document.body.appendChild(link)
				link.click()

				dispatch(recieveBuildPackage())
			})
			.catch(err => dispatch(failedBuildPackage(err)))
	}
}

function requestProjectDownload() {
	return {
		type: types.PROJECT_DOWNLOAD_REQUEST
	}
}

function recieveProjectDownload(project) {
	return {
		type: types.PROJECT_DOWNLOAD_SUCCESS
	}
}

function failedProjectDownload(err) {
	return {
		type: types.PROJECT_DOWNLOAD_FAILURE,
		err
	}
}

export function projectDownload(id) {
	return dispatch => {
		dispatch(requestProjectDownload())
		return fetch(`/api/project/${id}/result`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => {
				const link = document.createElement('a')

				const url = window.URL.createObjectURL(res._bodyBlob)
				link.href = url

				const filename = utils.getFileNameFromResponse(res)
				link.setAttribute('download', filename)

				document.body.appendChild(link)
				link.click()

				dispatch(recieveProjectDownload())
			})
			.catch(err => dispatch(failedProjectDownload(err)))
	}
}

function requestFormulaExport() {
	return {
		type: types.FORMULA_EXPORT_REQUEST
	}
}

function recieveFormulaExport() {
	return {
		type: types.FORMULA_EXPORT_SUCCESS
	}
}

function failedFormulaExport(err) {
	return {
		type: types.FORMULA_EXPORT_FAILURE,
		err
	}
}

export function formulaExport(id) {
	return dispatch => {
		dispatch(requestFormulaExport())
		return fetch(`/api/project/${id}/formula/export`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => {
				const link = document.createElement('a')

				const url = window.URL.createObjectURL(res._bodyBlob)
				link.href = url

				const filename = utils.getFileNameFromResponse(res)
				link.setAttribute('download', filename)

				document.body.appendChild(link)
				link.click()

				dispatch(recieveFormulaExport())
			})
			.catch(err => dispatch(failedFormulaExport(err)))
	}
}

function requestAssignUser() {
	return {
		type: types.PROJECT_ASSIGN_USER_REQUEST
	}
}

function recieveAssignUser(assigneeId) {
	return {
		type: types.PROJECT_ASSIGN_USER_SUCCESS,
		assigneeId
	}
}

function failedAssignUser(err) {
	return {
		type: types.PROJECT_ASSIGN_USER_FAILURE,
		err
	}
}

export function assignUser(projectId, userId) {
	return dispatch => {
		dispatch(requestAssignUser())
		return fetch(`/api/project/${projectId}/assign/${userId}`, { method: 'PUT' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(json => dispatch(recieveAssignUser(userId)))
			.catch(err => dispatch(failedAssignUser(err)))
	}
}

function requestUnAssignUser() {
  return {
    type: types.PROJECT_UNASSIGN_USER_REQUEST
  }
}

function recieveUnAssignUser() {
  return {
    type: types.PROJECT_UNASSIGN_USER_SUCCESS
  }
}

function failedUnAssignUser(err) {
  return {
    type: types.PROJECT_UNASSIGN_USER_FAILURE,
    err
  }
}

export function unAssignUser(projectId) {
  return dispatch => {
    dispatch(requestUnAssignUser())
    return fetch(`/api/project/${projectId}/assign/`, { method: 'DELETE' })
      .then(res => utils.handleErrors(res, dispatch))
      .then(json => dispatch(recieveUnAssignUser()))
      .catch(err => dispatch(failedUnAssignUser(err)))
  }
}

function requestSetRegInfo() {
	return {
		type: types.PROJECT_SET_REG_INFO_REQUEST
	}
}

function recieveSetRegInfo(regNum, regDate, regUrl) {
	return {
		type: types.PROJECT_SET_REG_INFO_SUCCESS,
		regNum,
		regDate,
		regUrl
	}
}

function failedSetRegInfo(err) {
	return {
		type: types.PROJECT_SET_REG_INFO_FAILURE,
		err
	}
}

export function setRegInfo(projectId, regNum, regDate, regUrl) {
	const params = {
		method: 'PUT',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			regNum,
			regDate,
			regUrl
		})
	  }

	return dispatch => {
		dispatch(requestSetRegInfo())
		return fetch(`/api/project/${projectId}/reginfo`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(json => dispatch(recieveSetRegInfo(regNum, regDate, regUrl)))
			.catch(err => dispatch(failedSetRegInfo(err)))
	}
}

function requestProjectSetComment(comment) {
	return {
		type: types.PROJECT_SET_COMMENT_REQUEST,
		comment
	}
}

function recieveProjectSetComment(comment) {
	return {
		type: types.PROJECT_SET_COMMENT_SUCCESS,
		comment
	}
}

function failedProjectSetComment(err) {
	return {
		type: types.PROJECT_SET_COMMENT_FAILURE,
		err
	}
}

export function projectSetComment(id, comment) {
	const params = {
		method: 'PUT',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ comment })
	}

	return dispatch => {
		dispatch(requestProjectSetComment(comment))
		return fetch(`/api/project/${id}/comment`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(() => dispatch(recieveProjectSetComment(comment)))
			.catch(err => dispatch(failedProjectSetComment(err)))
	}
}

function requestProjectSetReadOnly(readOnly) {
	return {
		type: types.PROJECT_RO_REQUEST,
		readOnly
	}
}

function recieveProjectSetReadOnly(readOnly) {
	return {
		type: types.PROJECT_RO_SUCCESS,
		readOnly
	}
}

function failedProjectSetReadOnly(err) {
	return {
		type: types.PROJECT_RO_FAILURE,
		err
	}
}

export function projectSetReadOnly(id, readOnly) {
	const params = {
		method: 'PUT',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ readOnly })
	}
	return dispatch => {
		dispatch(requestProjectSetReadOnly(readOnly))
		return fetch(`/api/project/${id}/ro`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(json => dispatch(recieveProjectSetReadOnly(readOnly)))
			.then(() => {
				if (readOnly) {
					dispatch(getView(id))
					dispatch(cbrCheckProject(id))
					dispatch(formulaCheckProject(id))
				}
			})
			.catch(err => dispatch(failedProjectSetReadOnly(err)))
	}
}

export function setOkudCodeForAdd(code) {
	return {
		type: types.PROJECT_SET_OKUD_CODE_FOR_ADD,
		code
	}
}

export function resetOkudCodeForAdd() {
	return {
		type: types.PROJECT_RESET_OKUD_CODE_FOR_ADD
	}
}

export function setOkudIdForEdit(id) {
	return {
		type: types.PROJECT_SET_OKUD_ID_FOR_EDIT,
		id
	}
}

export function resetOkudIdForEdit() {
	return {
		type: types.PROJECT_RESET_OKUD_ID_FOR_EDIT
	}
}

export function clearUploadErrors() {
	return {
		type: types.PROJECT_CLEAR_UPLOAD_ERRORS
	}
}

function requestRemoveProject() {
	return {
		type: types.PROJECT_REMOVE_REQUEST
	}
}

function recieveRemoveProject(id) {
	return {
		type: types.PROJECT_REMOVE_SUCCESS,
		id
	}
}

function failedRemoveProject(err) {
	return {
		type: types.PROJECT_REMOVE_FAILURE,
		err
	}
}

export function removeProject(id) {
	const params = {
		method: 'DELETE',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	}

	return dispatch => {
		dispatch(requestRemoveProject())
		return fetch(`/api/project/${id}`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => dispatch(recieveRemoveProject(id)))
			.catch(err => dispatch(failedRemoveProject(err)))
	}
}

function requestArchiveProject() {
	return {
		type: types.PROJECT_ARCHIVE_REQUEST
	}
}

function recieveArchiveProject(id) {
	return {
		type: types.PROJECT_ARCHIVE_SUCCESS,
		id
	}
}

function failedArchiveProject(err) {
	return {
		type: types.PROJECT_ARCHIVE_FAILURE,
		err
	}
}

export function archiveProject(id) {
	const params = {
		method: 'PUT',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	}

	return dispatch => {
		dispatch(requestArchiveProject())
		return fetch(`/api/project/${id}/archive`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => dispatch(recieveArchiveProject(id)))
			.catch(err => dispatch(failedArchiveProject(err)))
	}
}

function requestRestoreProject() {
	return {
		type: types.PROJECT_RESTORE_REQUEST
	}
}

function recieveRestoreProject(id) {
	return {
		type: types.PROJECT_RESTORE_SUCCESS,
		id
	}
}

function failedRestoreProject(err) {
	return {
		type: types.PROJECT_RESTORE_FAILURE,
		err
	}
}

export function restoreProject(id) {
	const params = {
		method: 'PUT',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	}

	return dispatch => {
		dispatch(requestRestoreProject())
		return fetch(`/api/project/${id}/restore`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => dispatch(recieveRestoreProject(id)))
			.catch(err => dispatch(failedRestoreProject(err)))
	}
}

