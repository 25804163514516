import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  Collapse,
  Navbar as ReactNavbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

const Navbar = ({ userName, authenticated, onLogOut }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen)

    return (
      <div className="navbar-component">
        <div className="container">
          <ReactNavbar color="light" light expand="md">
            <NavbarBrand href="/">XBRL</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse className="justify-content-end" isOpen={isOpen} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink className="nav-link project-list" to="/project/list">
                    Мои отчеты
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link new-project" to="/project/new">
                    Новый отчет
                  </NavLink>
                </NavItem>
                
                {
                  authenticated &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      <i class="fas fa-user-circle"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink className="nav-link" to="/company">
                          Управление
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={onLogOut}>
                        Выход
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                }

                {
                  !authenticated &&
                  <NavItem>
                    <a className="nav-link">Войти</a>
                  </NavItem>
                }
              </Nav>
            </Collapse>
          </ReactNavbar>
        </div>
      </div>
    )
}

Navbar.propTypes = {
  userName: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired,
  onLogOut: PropTypes.func.isRequired
}

export default Navbar
