import React, { useState } from 'react'
import PropTypes from 'prop-types'

const OnDemandMultiSelect = ({ list, nameField, valueField, resultField, initialValue, onSelect, divModeClassName, listModeClassName }) => {
  const [isListMode, setListMode] = useState(false)
  const [value, setValue] = useState(initialValue)

  const onFocus = (evt) => {
    setListMode(true)
  }

  const onBlur = (evt) => {
    setListMode(false)
  }

  const prepareValue = (str) => {
    return str.sort().join(" ")
  }

  const onChange = (evt) => {
    const options = evt.target.options
    const selectedValues = []
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value)
      }
    }

    const prepared = prepareValue(selectedValues)
    if (evt.target.prevValue !== prepared) {
      evt.target.prevValue = prepared

      const res = {}
      res[resultField] = prepared
      onSelect(res)
    }

    setValue(selectedValues)
  }

  let cellValue = value
  if(!Array.isArray(cellValue)){
    cellValue = cellValue.split(' ')
  }

  const selectedItems = list.filter((item) => cellValue.includes(item[valueField]))
  const dn = selectedItems.map(item => item[nameField]).join(' ')

  if (isListMode) {
    return (
        <select className={listModeClassName} onBlur={onBlur} onChange={onChange} multiple value={cellValue}>
          {
            list.map((item) => <option key={item[valueField]} value={item[valueField]}>{item[nameField]}</option>)
          }
        </select>
    )
  } else {
    return (
      <div 
        className={divModeClassName}
        contentEditable
        suppressContentEditableWarning 
        onFocus={onFocus}>
          {dn}
      </div>
    )
  }
}

OnDemandMultiSelect.propTypes = {
  list: PropTypes.array.isRequired,
  nameField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  resultField: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  divModeClassName: PropTypes.string,
  listModeClassName: PropTypes.string
}

export default OnDemandMultiSelect
