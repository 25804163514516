import fetch from 'cross-fetch'
import { push } from 'connected-react-router'

import * as types from './types'
import * as utils from './utils'

function requestGetCompany() {
	return {
		type: types.GET_COMPANY_REQUEST
	}
}

function recieveGetCompany(company) {
	return {
		type: types.GET_COMPANY_SUCCESS,
		company
	}
}

function failedGetCompany(err) {
	return {
		type: types.GET_COMPANY_FAILURE,
		err
	}
}

export function getCompany() {
	return dispatch => {
		dispatch(requestGetCompany())
		return fetch(`/api/company`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveGetCompany(json)))
			.catch(err => dispatch(failedGetCompany(err)))
	}
}

function requestSetCompany() {
	return {
		type: types.SET_COMPANY_REQUEST
	}
}

function recieveSetCompany() {
	return {
		type: types.SET_COMPANY_SUCCESS
	}
}

function failedSetCompany(err) {
	return {
		type: types.SET_COMPANY_FAILURE,
		err
	}
}

export function setCompany(name, identifier) {
	const params = {
		method: 'PUT',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ name, identifier })
	}

	return dispatch => {
		dispatch(requestSetCompany())
		return fetch(`/api/company`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => dispatch(recieveSetCompany()))
			.catch(err => dispatch(failedSetCompany(err)))
	}
}

function requestGetUsers() {
	return {
		type: types.GET_USERS_REQUEST
	}
}

function recieveGetUsers(users) {
	return {
		type: types.GET_USERS_SUCCESS,
		users
	}
}

function failedGetUsers(err) {
	return {
		type: types.GET_USERS_FAILURE,
		err
	}
}

export function getUsers() {
	return dispatch => {
		dispatch(requestGetUsers())
		return fetch(`/api/company/users`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveGetUsers(json)))
			.catch(err => dispatch(failedGetUsers(err)))
	}
}

function requestInviteUser() {
	return {
		type: types.INVITE_USER_REQUEST
	}
}

function recieveInviteUser(link) {
	const msg = 'Пользователю отправлено письмо с приглашением. Для завершения регистрации ему необходимо перейти по ссылке из этого письма.'
	alert(msg)

	return {
		type: types.INVITE_USER_SUCCESS
	}
}

function failedInviteUser(err) {
	return {
		type: types.INVITE_USER_FAILURE,
		err
	}
}

export function inviteUser(name, email) {
	const params = {
		method: 'PUT',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ name, email })
	}

	return dispatch => {
		dispatch(requestInviteUser())
		return fetch(`/api/company/invite`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => dispatch(recieveInviteUser()))
			.catch(err => dispatch(failedInviteUser(err)))
	}
}


function requestAddFund() {
	return {
		type: types.ADD_FUND_REQUEST
	}
}

function recieveAddFund(fund) {
	return {
		type: types.ADD_FUND_SUCCESS,
		fund
	}
}

function failedAddFund(err) {
	return {
		type: types.ADD_FUND_FAILURE,
		err
	}
}

export function addFund(name, type, isKval, cancelDate, aspectValue) {
	const fund = { name, type, isKval, cancelDate, aspectValue }
	const params = {
		method: 'POST',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify(fund)
	}

	return dispatch => {
		dispatch(requestAddFund())
		return fetch(`/api/company/fund`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then((json) => dispatch(recieveAddFund(json)))
			.catch(err => dispatch(failedAddFund(err)))
	}
}

function requestEditFund() {
	return {
		type: types.Edit_FUND_REQUEST
	}
}

function recieveEditFund(fund) {
	return {
		type: types.Edit_FUND_SUCCESS,
		fund
	}
}

function failedEditFund(err) {
	return {
		type: types.Edit_FUND_FAILURE,
		err
	}
}

export function editFund(id, name, type, isKval, cancelDate, aspectValue) {
	const fund = { name, type, isKval, cancelDate, aspectValue }
	const params = {
		method: 'PUT',
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify(fund)
	}

	return dispatch => {
		dispatch(requestEditFund())
		return fetch(`/api/company/fund/${id}`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then((json) => dispatch(recieveEditFund(json)))
			.catch(err => dispatch(failedEditFund(err)))
	}
}

function requestDeleteFund() {
	return {
	  type: types.DELETE_FUND_REQUEST
	}
}

function recieveDeleteFund(id) {
	return {
		type: types.DELETE_FUND_SUCCESS,
		id
	}
}

function failedDeleteFund(err) {
	return {
		type: types.DELETE_FUND_FAILURE,
		err
	}
}

export function deleteFund(id) {
	return dispatch => {
		dispatch(requestDeleteFund())
		return fetch(`/api/company/fund/${id}`, { method: 'DELETE' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(() => dispatch(recieveDeleteFund(id)))
			.catch(err => dispatch(failedDeleteFund(err)))
	}
}

function requestFundTypes() {
	return {
	  type: types.GET_FUND_TYPES_REQUEST
	}
}

function recieveFundTypes(fundTypes) {
	return {
		type: types.GET_FUND_TYPES_SUCCESS,
		fundTypes
	}
}

function failedFundTypes(err) {
	return {
		type: types.GET_FUND_TYPES_FAILURE,
		err
	}
}

export function getFundTypes() {
	return dispatch => {
		dispatch(requestFundTypes())

		return fetch(`/ui/fundtype/list`, { method: 'GET' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveFundTypes(json)))
			.catch(err => dispatch(failedFundTypes(err)))
	}
}