import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { push } from 'connected-react-router'
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes/RouteWithSubRoutes'
import Steps from '../../components/Steps/Steps'
import ProjectInfo from '../../components/ProjectInfo/ProjectInfo'

class Okud extends Component {
	render() {
		const { id, routes, authenticated, push } = this.props

		return (
			<div className="okud-container">
				<Switch>
					{
						routes.map((route, i) => (
							<RouteWithSubRoutes key={i} authenticated={() => authenticated} {...route} />
						))
					}
				</Switch>
			</div>
		)
	}
}

Okud.propTypes = {
	id: PropTypes.string.isRequired,
	routes: PropTypes.object.isRequired,
	authenticated: PropTypes.bool.isRequired,
	push: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
	const id = ownProps.match.params.projectId

	const routes = ownProps.routes
	const authenticated = state.user.authenticated

	return {
		id,
		authenticated,
		routes
	}
}

export default connect(mapStateToProps, { push })(Okud)
