import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'
import Select from 'react-select'
import Moment from 'react-moment'
import './ProjectSelect.css'

class ProjectSelect extends Component {
  render() {
    const { projects, selectedId, onSelect } = this.props
    const validProjects = projects.filter((project) => project.info && !project.err)

    const searchString = ({ id, info, aspects }) => {
      const searchAspects = aspects.map((aspect) => `${aspect.name} ${aspect.values.join()}`)
      return `${info.nfoTypeCode} ${info.dtsVersion} ${info.periodName} ${info.periodType} ${info.reportType} ${searchAspects}`
    }

    return (
      <div className="project-select">
        <Label>Выберите базовый отчет</Label>
        <Select
          formatOptionLabel={formatOptionLabel}
          getOptionLabel={searchString}
          getOptionValue={({ id }) => id}
          placeholder="Выберите отчет"
          options={validProjects}
          onChange={onSelect}
        />
      </div>
    )
  }
}

const formatOptionLabel = ({ id, info, aspects }) => (
  <div>
    <div>{info.reportType}</div>

    <div className="project-item-params">
      <span>[{info.periodName}]</span>
      <span>[{info.dtsVersion}]</span>
      <span>[{info.nfoTypeCode}]</span>
      <span>[{info.reportTypeCode}]</span>
      <span>[{info.periodTypeCode}]</span>
    </div>

    <dl className="project-item-aspects">
      {
        aspects.map((aspect) => {
          return (
            <span>
              <dt>{aspect.name}</dt>
              <dd>{aspect.values.join()}</dd>
            </span>
          )
        })
      }
    </dl>
    
    <div className="project-item-comment">{info.comment}</div>
  </div>
)

ProjectSelect.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.object,
    aspects: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string)
    })),
    err: PropTypes.string
  })).isRequired,

  selectedId: PropTypes.string,

  onSelect: PropTypes.func.isRequired
}

export default ProjectSelect
