import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from './reducers'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['user']
}

export const history = createBrowserHistory()

const persistedReducer = persistReducer(persistConfig, rootReducer(history))

export default function configureStore() {
	const store = createStore(
		persistedReducer,
		compose(
			applyMiddleware(thunk),
			applyMiddleware(routerMiddleware(history)),
			typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
		)
	)

	const persistor = persistStore(store)

	return { store, persistor }
}