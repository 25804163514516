import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { push } from 'connected-react-router'
import { getProject, changeProject } from '../../actions/project'
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes/RouteWithSubRoutes'
import Steps from '../../components/Steps/Steps'
import ProjectInfo from '../../components/ProjectInfo/ProjectInfo'
import { Breadcrumbs } from 'react-breadcrumbs'

class Project extends Component {
	componentDidMount() {
		const { id, getProject, changeProject } = this.props
		changeProject(id)
		getProject(id)
	}

	render() {
		const { id, projectName, routes, authenticated, push } = this.props

		return (
			<div>
		        <Breadcrumbs className="breadcrumps-container" />

				<div className="project-container">
					<Switch>
						{
							routes.map((route, i) => (
								<RouteWithSubRoutes key={i} authenticated={() => authenticated} {...route} />
							))
						}
					</Switch>
				</div>
			</div>
		)
	}
}

Project.propTypes = {
	id: PropTypes.string.isRequired,
	projectName: PropTypes.string.isRequired,
	routes: PropTypes.object.isRequired,
	authenticated: PropTypes.bool.isRequired,
	getProject: PropTypes.func.isRequired,
	changeProject: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
	const id = ownProps.match.params.projectId

	const routes = ownProps.routes
	const authenticated = state.user.authenticated

	return {
		id,
		projectName: state.project.name || 'Отчет',
		authenticated,
		routes
	}
}

export default connect(mapStateToProps, { getProject, changeProject, push })(Project)
