import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './CheckEntry.css'

class CheckEntry extends Component {
  render() {
    const entry = this.props.entry
    console.log(entry)

    return (
      <div className="check-entry bg_white">
        <div className="flex_align-center">
          <div className="check-entry-title">{entry.dn}</div>
          { entry.count && <div className="check-entry-count flex">{entry.count}</div> }
        </div>

        <div className="check-entry-descr">
          {entry.descr}
        </div>

        <div className="check-entry-details">
          { entry.details }
        </div>
      </div>
    )
  }
}

CheckEntry.propTypes = {
  entry: PropTypes.shape({
    dn: PropTypes.string.isRequired,
    descr: PropTypes.string,
    count: PropTypes.number,
    details:PropTypes.object
  }).isRequired
}

export default CheckEntry