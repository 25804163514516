import React from 'react'
import PropTypes from 'prop-types'
import './FileDropZone.css'

const FileDropZone = () => {
  // https://codepen.io/mattsince87/pen/yadZXv
  // https://codepen.io/daleitch/pen/yyNdvG
  return (
    <div className="drop-zone" id="dropZone">
      <div>
        <i className="fas fa-download"></i>
      </div>
      <div className="drop-zone-title">Нажмите, чтобы загрузить или перетащить файлы в эту область</div>
      <div className="drop-zone-descr">
        <p>Мы объединим их в один, проверим на ошибки и отобразим результат в понятном виде.</p>
        <p>После этого можно скачть результат и отправить его регулятору.</p>
      </div>
    </div>
  )
}

FileDropZone.propTypes = {
}

export default FileDropZone
