import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDom from 'react-dom'
import { Button, Form, FormGroup, Label, Input, FormText, Spinner } from 'reactstrap'
import './ProjectReadOnlyManager.css'

class ProjectReadOnlyManager extends Component {
  render() {
    const { readOnly, onChange } = this.props
    
    const onClick = (evt) => {
      this.props.onChange(!readOnly)
    }

    return (
      <Form className='ro-form'>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={readOnly} onClick={onClick} />{' '}
              Отчет доступен только для чтения
              {/*<Spinner size="sm" color="secondary" />*/}

              <p className='ro-descr'>
                <div>Для внесения изменений снимите галку "Отчет доступен только для чтения"</div>
                <div>После внесения изменения поставьте галку "Отчет доступен только для чтения"</div>
              </p>
            </Label>
          </FormGroup>
        </Form>

    )
  }
}

ProjectReadOnlyManager.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ProjectReadOnlyManager
