import { combineReducers } from 'redux'
import produce from 'immer'
import { LOCATION_CHANGE } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'
import * as types from '../actions/types'

const defaultState = {
  id: null,
  code: null,
  info: null,
  cbrCheckLog: null,
  formulaCheckLog: null,
  view: null,
  editorMode: false,
  changes: [],
  isSaving: false,
  lastSaveTime: null
}

export default function (state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.VIEW_REQUEST:
        draft.view = null
        break

      case types.VIEW_SUCCESS:
        draft.view = action.view

        if (draft.view && draft.view._grids) {
          draft.code = draft.view._grids.reduce((res, grid) => {
            const index = grid.label.indexOf(' ')
            if (index > -1) {
              return grid.label.substring(0, index)
            }

            return res
          }, draft.id)
        }
        break

      case types.OKUD_CBR_CHECK_REQUEST:
        draft.cbrCheckLog = null
        break

      case types.OKUD_CBR_CHECK_SUCCESS:
        draft.cbrCheckLog = action.cbrCheckLog
        break

      case types.OKUD_FORMULA_CHECK_REQUEST:
        draft.formulaCheckLog = null
        break

      case types.OKUD_FORMULA_CHECK_SUCCESS:
        draft.formulaCheckLog = action.formulaCheckLog || []
        break

      case types.UPD_CELL:
        draft.changes.push(action.change)

        updateCell(
          draft.view,
          action.change.tableId,
          action.change.rowIndex,
          action.change.cellId,
          action.change.value
        )
        break

      case types.EDITOR_ADD_ROW:
        draft.changes.push(action.change)

        addRow(draft.view, action.change.tableId, action.change.rowIndex)
        break

      case types.EDITOR_REMOVE_ROW:
        draft.changes.push(action.change)

        removeRow(draft.view, action.change.tableId, action.change.rowIndex)
        break

      case types.EDITOR_COPY_ROW:
        draft.changes.push(action.change)

        addRow(draft.view, action.change.tableId, action.change.rowIndex, true)
        break

      case types.SAVE_REQUEST:
        draft.isSaving = true
        break

      case types.SAVE_SUCCESS:
        draft.isSaving = false
        draft.lastSaveTime = new Date()
        draft.changes.splice(0, action.changes.length)

        draft.cbrCheckLog = null
        draft.formulaCheckLog = null
        break

      case LOCATION_CHANGE:
        const pathname = action.payload.location.pathname
        if (!pathname.endsWith('/view')) {
          draft.view = null
        }
        break

      case types.LOGOUT_USER_SUCCESS:
        return defaultState
      
      default:
        return draft
    }
  })
}

function getTable(state, tableId) {
  return state._grids.find((grid) => grid.tag === tableId)
}

function addRow(state, tableId, rowIndex, copyFlag) {
  const table = getTable(state, tableId)

  const rowsStruct = table.struct.rows

  const index = rowsStruct.findIndex((row) => row.size)
  for (let i = 0; i < rowsStruct[index].size; i++) {
    const rowStruct = rowsStruct[index + i]

    const cells = table.struct.cols.map((col) => {
      const cell = { id: col.id }

      if (col.id.startsWith('h') && rowStruct.headers) {
        const header = rowStruct.headers.find((h) => h.id === col.id)
        if (header) {
          cell.data = header.data
        }
      } else if (copyFlag) {
        const origCell = table._rows[rowIndex + i]._cells.find((c) => c.id === cell.id)
        if (cell.id.startsWith('c')) {
          cell.data = origCell.data  
        } else if (cell.id.startsWith('d')) {
          // cell.data = `${tableId}_${}_${cell.id}` +(new Date())
        }
      }

      const type = rowStruct.type || col.type

      if (type === 'decimal' || type === 'monetary') {
        cell.decimals = state._properties.defaultDecimals
      }

      if (type === 'monetary') {
        cell.currency = state._properties.defaultCurrency
      }
      
      return cell
    })

    const row = {
      unbounded: true,
      size: rowStruct.size,
      index: i,
      aspects: [],
      tag: 'row',
      type: rowStruct.type,
      list: rowStruct.list,
      uuid: uuidv4(),
      _cells: cells
    }

    table._rows.splice(rowIndex + i, 0, row)
  }
}

function removeRow(state, tableId, rowIndex) {
  const table = getTable(state, tableId)

  let size = 1

  const rowsStruct = table.struct.rows
  if (rowsStruct) {
    const struct = rowsStruct.find((row) => row.size)
    if (struct) {
      size = struct.size
    }
  }

  table._rows.splice(rowIndex, size)
}

function updateCell(state, tableId, rowIndex, cellId, value) {
  const table = getTable(state, tableId)
  if (!table) return state

  const row = table._rows[rowIndex]
  if (!row) return state

  let cell = row._cells.find((cell) => cell.id === cellId)
  if (!cell) return state
  cell.data = value

  return state
}
