import { combineReducers } from 'redux'
import * as types from '../actions/types'

const isLogin = (state = true, action) => {
  switch (action.type) {
    case types.TOGGLE_LOGIN_MODE:
      return !state
    default:
      return state
  }
}

const authenticated = (state = false, action) => {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
    case types.SIGNUP_USER_SUCCESS:
    case types.USER_RESET_SUCCESS:
    case types.LOGOUT_USER_FAILURE:
      return true
    case types.LOGIN_USER_FAILURE:
    case types.SIGNUP_USER_FAILURE:
    case types.USER_RESET_FAILURE:
    case types.LOGOUT_USER_SUCCESS:
    case types.USER_ANAUTHORIZED:
      return false
    default:
      return state
  }
}

const userReducer = combineReducers({
  isLogin,
  authenticated
})

export default userReducer
