'use strict';

var router = require('connected-react-router')

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FILE_UPLOAD_PROGRESS = exports.FILE_UPLOAD_MULTIPLE_FILE_UPLOAD = exports.FILE_UPLOAD_COMPLETE = exports.FILE_UPLOAD_ERROR = exports.FILE_UPLOAD_ADD_UPLOADING_IMAGES_SUCCESS = exports.FILE_UPLOAD_ADD_UPLOADING_IMAGES = exports.FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS_SUCCESS = exports.FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS = exports.THUMBNAIL_HEIGHT = exports.THUMBNAIL_WIDTH = undefined;
exports.addUploadingImages = addUploadingImages;
exports.addUploadingDocs = addUploadingDocs;
exports.uploadFiles = uploadFiles;
exports.fileProgress = fileProgress;
exports.fileComplete = fileComplete;

var _detectNode = require('detect-node');

var _detectNode2 = _interopRequireDefault(_detectNode);

var _bluebird = require('bluebird');

var _bluebird2 = _interopRequireDefault(_bluebird);

var _helpers = require('./helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FileAPI = !_detectNode2.default ? require('fileapi') : null;

var THUMBNAIL_WIDTH = exports.THUMBNAIL_WIDTH = 200;
var THUMBNAIL_HEIGHT = exports.THUMBNAIL_HEIGHT = 200;

var FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS = exports.FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS = 'FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS';
var FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS_SUCCESS = exports.FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS_SUCCESS = 'FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS_SUCCESS';
var FILE_UPLOAD_ADD_UPLOADING_IMAGES = exports.FILE_UPLOAD_ADD_UPLOADING_IMAGES = 'FILE_UPLOAD_ADD_UPLOADING_IMAGES';
var FILE_UPLOAD_ADD_UPLOADING_IMAGES_SUCCESS = exports.FILE_UPLOAD_ADD_UPLOADING_IMAGES_SUCCESS = 'FILE_UPLOAD_ADD_UPLOADING_IMAGES_SUCCESS';
var FILE_UPLOAD_ERROR = exports.FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
var FILE_UPLOAD_COMPLETE = exports.FILE_UPLOAD_COMPLETE = 'FILE_UPLOAD_COMPLETE';
var FILE_UPLOAD_MULTIPLE_FILE_UPLOAD = exports.FILE_UPLOAD_MULTIPLE_FILE_UPLOAD = 'FILE_UPLOAD_MULTIPLE_FILE_UPLOAD';
var FILE_UPLOAD_PROGRESS = exports.FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';

function getThumbnails(imageFiles) {
  return _bluebird2.default.all(imageFiles.map(getImageThumbnail));
}

function getImageThumbnail(imageFile) {
  return new _bluebird2.default(function (resolve, reject) {
    FileAPI.Image(imageFile).preview(THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT).get(function (err, canvas) {
      if (err) reject(err);

      resolve({
        dataURL: canvas.toDataURL(),
        file: imageFile
      });
    });
  });
}

function uploadFile(dispatch, url, identificator, file, data) {
  return new _bluebird2.default(function (resolve) {
    FileAPI.upload({
      data: data,
      files: {
        file: file
      },
      complete: function(err, xhr) {
        const { id } = JSON.parse(xhr.responseText)
        resolve(id)
      },
      fileprogress: function fileprogress() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return dispatch(fileProgress.apply(undefined, [identificator].concat(args)));
      },
      filecomplete: function filecomplete() {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        return dispatch(fileComplete.apply(undefined, [identificator].concat(args)));
      },
      url: url
    });
  });
}

function addUploadingImages(identificator, imageFiles) {
  return {
    type: FILE_UPLOAD_ADD_UPLOADING_IMAGES,
    payload: {
      promise: getThumbnails(imageFiles)
    },
    meta: {
      identificator: identificator
    }
  };
}

function addUploadingDocs(identificator, docFiles) {
  var documentPromise = _bluebird2.default.all(docFiles);

  return {
    type: FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS,
    payload: {
      promise: documentPromise
    },
    meta: {
      identificator: identificator
    }
  };
}

function uploadFiles(identificator, url, files, type, data) {
  var concurrency = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 2;

  return function (params) {
    var dispatch = typeof params === 'function' ? params : params.dispatch;
    
    uploadFile(dispatch, url, identificator, files[0], data).then((id) => {
      if (id && !url.includes(`/${id}/upload`)) {
        url = url.replace('/upload', `/${id}/upload`)
      }
      files = files.slice(1)

      var uploadFilePromise = _bluebird2.default.map(files, function (file) {
        return uploadFile(dispatch, url, identificator, file, data);
      }, { concurrency: 1 });

      uploadFilePromise.then(() => {
        if (id) {
          dispatch(router.push(`/project/${id}`))
        }
      })

      // return {
      //   type: FILE_UPLOAD_MULTIPLE_FILE_UPLOAD,
      //   payload: {
      //     promise: uploadFilePromise
      //   }
      // };
    })
  };
}

function fileProgress(identificator, event, file, fileType) {
  var progress = event.loaded / event.total * 100;

  return {
    type: FILE_UPLOAD_PROGRESS,
    payload: { identificator: identificator, file: file, fileType: fileType, progress: progress, isImage: (0, _helpers.isImage)(file), isDoc: (0, _helpers.isDoc)(file) }
  };
}

function fileComplete(identificator, error, xhr, file) {
  if (error) return {
    type: FILE_UPLOAD_ERROR,
    payload: { identificator: identificator, file: file, error: xhr.response, isImage: (0, _helpers.isImage)(file), isDoc: (0, _helpers.isDoc)(file) }
  };

  return function (params) {
    var dispatch = typeof params === 'function' ? params : params.dispatch;

    var response = JSON.parse(xhr.response);
    var photo = response.photo;


    dispatch({
      type: FILE_UPLOAD_COMPLETE,
      payload: { identificator: identificator, file: file, photo: photo, response: response }
    })
    
    // dispatch(router.push(`/project/${response.id}`))
  }
}