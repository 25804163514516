import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckEntry from '../CheckEntry/CheckEntry'
import './CheckCbr.css'

class CheckCbr extends CheckEntry {
  render() {
    const cbrEntry = this.props.entry
    const entry = {
      dn: cbrEntry.dn,
      descr: cbrEntry.descr,
      count: cbrEntry.entries.length,
      details: this.renderDetails(cbrEntry)
    }

    return (
      <CheckEntry entry={entry} />
    )
  }

  renderDetails(entry) {
    return (
      <ul className="check-cbr-list">
        { entry.entries.map((item) => <li>{item}</li>) }
      </ul>
    )
  }
}

CheckCbr.propTypes = {
  entry: PropTypes.shape({
    dn: PropTypes.string.isRequired,
    descr: PropTypes.string.isRequired,
    entries: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired
}

export default CheckCbr