import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CbrRegInfoModal from '../CbrRegInfoModal/CbrRegInfoModal'
import Moment from 'react-moment'
import './ProjectStatus.css'

const ProjectStatus = ({ status, regNum, regDate, regUrl, setRegInfo }) => {
  const [isCbrInfoModalOpen, setCbrInfoModalOpen] = useState(false)

  let statusLabel
  let regBlock

  if (status === 'inprogress') {
    statusLabel = <span className="project-status-label text-warning">В работе</span>
  } else if (regNum) {
    statusLabel = <span className="project-status-label text-success">Сделано</span>
    const className = typeof setRegInfo === 'function' ? 'project-reg-block-inline' : null
    regBlock = (
      <dl className={`project-reg-block ${className}`}>
        <dt>Рег номер</dt>
        <dd>
          <a href={regUrl} target="_blank">
            {regNum} от <Moment className="project-reg-date" format="DD.MM.YYYY">{regDate}</Moment>
          </a>
        </dd>
      </dl>
    )
  } else {
    statusLabel = <span className="project-status-label text-info">Ждет отправки в ЦБ</span>
    if (typeof setRegInfo === 'function') {
      regBlock = <span className="pseudo-link" onClick={() => setCbrInfoModalOpen(true)}>Ввести рег номер</span>
    }
  }

  return (
    <div className="project-status">
      {statusLabel}
      {regBlock}

      <CbrRegInfoModal
        isOpen={isCbrInfoModalOpen}
        onClose={() => setCbrInfoModalOpen(false)}
        onSave={setRegInfo}
      />
    </div>
  )
}

ProjectStatus.propTypes = {
  status: PropTypes.string,
  regNum: PropTypes.string,
  regDate: PropTypes.instanceOf(Date),
  regUrl: PropTypes.string,
  setRegInfo: PropTypes.func.isRequired
}

export default ProjectStatus
