import { combineReducers } from 'redux'
import produce from 'immer'
import * as types from '../actions/types'

const defaultState = {
  name: null,
  identifier: null,
  users: [],
  funds: [],
  fundTypes: []
}

export default function (state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_COMPANY_SUCCESS:
        draft.name = action.company.name
        draft.identifier = action.company.identifier
        draft.users = action.company.users // Что если нет пользователей, по аналогии с фондами?
        draft.funds = action.company.funds || defaultState.funds
        break

      case types.GET_USERS_SUCCESS:
        draft.users = action.users
        break

      case types.LOGOUT_USER_SUCCESS:
        return defaultState

      case types.ADD_FUND_SUCCESS:
        draft.funds.push(action.fund)
        break

      case types.Edit_FUND_SUCCESS:
        draft.funds.some((fund, i, funds) => {
          if (fund._id === action.fund._id) {
            funds[i] = action.fund
            return true
          }
        })
        break

      case types.DELETE_FUND_SUCCESS:
        draft.funds.some((fund, i, funds) => {
          if (fund._id === action.id) {
            funds.splice(i, 1)
            return true
          }
        })
        break

      case types.GET_FUND_TYPES_SUCCESS:
        draft.fundTypes = action.fundTypes
        break

      default:
        return draft
    }
  })
}
