import { combineReducers } from 'redux'
import * as types from '../actions/types'

const isLoading = (state = false, action) => {
  // todo: display a small loader
  if (action.type === types.SAVE_REQUEST) {
    return false
  }

  if (action.type === types.FORMULA_CHECK_REQUEST || action.type === types.OKUD_FORMULA_CHECK_REQUEST) {
    return state || false
  }

  if (action.type.endsWith('REQUEST') || action.type.endsWith('PROGRESS')) {
    return true
  }

  return false
}

const requests = (state = [], action) => {
  if (action.type === types.SAVE_REQUEST || action.type === types.FORMULA_CHECK_REQUEST || action.type === types.OKUD_FORMULA_CHECK_REQUEST) {
    return state
  }

  if (action.type === 'FILE_UPLOAD_PROGRESS' && state.length && state[state.length - 1].type === 'FILE_UPLOAD_PROGRESS') {
    return state
  }

  if (action.type.endsWith('REQUEST') || action.type.endsWith('PROGRESS')) {
    
    return state.concat([{ type: action.type, msg: label('', action) }])

  } else if (action.type.endsWith('SUCCESS') || action.type.endsWith('COMPLETE') || action.type.endsWith('FAILURE') || action.type.endsWith('ERROR')) {

    const i = action.type.lastIndexOf('_')
    const baseType = action.type.slice(0, i)

    const index = state.findIndex((item) => item.type.includes(baseType))
    if (index > -1) {
      return [...state.slice(0, index), ...state.slice(index + 1)]
    }

  }

  if (action.type === types.LOGOUT_USER_SUCCESS) {
    return []
  }

  return state
}

const label = (state = '', action) => {
  switch (action.type) {
    case types.LOGIN_USER_REQUEST:
      return 'Вход в личный кабинет'
    case types.SIGNUP_USER_REQUEST:
      return 'Создание нового пользователя'
    case types.LOGOUT_USER_REQUEST:
      return 'Выход из личного кабинета'
    case types.GET_COMPANY_REQUEST:
      return 'Информация по компании'
    case types.SET_COMPANY_REQUEST:
      return 'Сохранение информации по компании'
    case types.CREATE_PROJECT_REQUEST:
      return 'Создание нового отчета'
    case types.GET_PROJECTS_REQUEST:
      return 'Загрузка отчетов пользователя'
    case types.GET_PROJECT_REQUEST:
      return 'Загрузка отчета'
    case types.PROJECT_DOWNLOAD_REQUEST:
      return 'Сборка xbrl файла'
    case types.BUILD_PACKAGE_REQUEST:
      return 'Сборка пакета отчетности'
    case types.GET_CURRENCY_LIST_REQUEST:
      return 'Получение списка валют'
    case types.VIEW_REQUEST:
      return 'Построение визуальной формы'
    case types.OKUD_EXCEL_REQUEST:
      return 'Выгрузка в excel'
    case types.CBR_CHECK_REQUEST:
    case types.OKUD_CBR_CHECK_REQUEST:
      return 'Проверка на соответствие правилам ЦБ'
    case types.OKUD_RESET_REQUEST:
      return 'Очистка данных отчетной формы'
    case types.OKUD_REMOVE_REQUEST:
      return 'Удаление отчетной формы'
    case types.FIX_REQUEST:
      return 'Исправляем ошибки'
    case types.FILE_REMOVE_REQUEST:
      return 'Удаление файла'
    case types.EDITOR_START_REQUEST:
      return 'Открытие редактора'
    case types.DRAFT_APPLY_REQUEST:
      return 'Применяем изменения черновика. Отчет будет перестроен и провалидирован'
    case types.DRAFT_DROP_REQUEST:
      return 'Удаляем черновик'
    case types.FILE_UPLOAD_PROGRESS:
      return 'Обработка файла'
    case types.PROJECT_SET_COMMENT_REQUEST:
      return 'Изменение комментария отчета'
    case types.PROJECT_RO_REQUEST:
      return 'Изменение статуса отчета'
    case types.PROJECT_ASSIGN_USER_REQUEST:
      return 'Назначаем исполнителя'
    case types.OKUD_UNASSIGN_USER_REQUEST:
      return 'Удаление исполнителя отчетной формы'
    case types.PROJECT_UNASSIGN_USER_REQUEST:
      return 'Удаление исполнителя отчета'
    default:
      return state
  }
}

const error = (state = '', action) => {
  if (action.type.endsWith('FAILURE')) {
    return action.err.message
  }

  if (action.type === types.FILE_UPLOAD_ERROR) {
    return action.payload.error
  }

  return ''
}

const isFormulaProcessed = (state = false, action) => {
  if (action.type === types.FORMULA_CHECK_REQUEST || action.type === types.OKUD_FORMULA_CHECK_REQUEST) {
    return true
  }

  if (action.type === types.FORMULA_CHECK_SUCCESS || action.type === types.FORMULA_CHECK_FAILURE ||
      action.type === types.OKUD_FORMULA_CHECK_SUCCESS || action.type === types.OKUD_FORMULA_CHECK_FAILURE) {
    return false
  }

  return state
}

const hideBackdrop = (state = false, action) => {
  return action.type === types.GET_PROJECTS_REQUEST && action.page > 0
 }

const loaderReducer = combineReducers({
  isLoading,
  isFormulaProcessed,
  hideBackdrop,
  requests,
  label,
  error
})

export default loaderReducer
