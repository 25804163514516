import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import './UserSelect.css'

const UserSelect = ({ users, selectedId, disabled, onChange, onRemove }) => {
  const onChange_ = (user) => {
    if (user) {
      onChange(user._id)
    } else {
      onRemove()
    }
  }

  const selectedUser = users.find((user) => user._id === selectedId)
  const selectedName = selectedUser ? (selectedUser.name || selectedUser.email) : 'Не назначен'

  const customStyles = {
    clearIndicator: () => ({
      paddingRight: 10,
      cursor: "pointer",
    }),
  }

  return (
    <Select
      placeholder="Не назначен"
      styles={customStyles}
      isClearable={!!selectedId}
      getOptionLabel={({ _id, name, email }) => name || email}
      getOptionValue={({ _id, name }) => _id}
      value={{ _id: selectedId, name: selectedName }}
      options={users}
      isDisabled={disabled}
      onChange={onChange_}
      classNamePrefix="userSelect"
    />
  )
}

UserSelect.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedId: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default UserSelect
