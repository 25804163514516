import fetch from 'cross-fetch'

import * as types from './types'
import { anauthorize } from './user'

function requestDtsList() {
	return {
		type: types.GET_DTS_LIST_REQUEST
	}
}

function recieveDtsList(list) {
	return {
		type: types.GET_DTS_LIST_SUCCESS,
		list
	}
}

function failedDtsList(err) {
	return {
		type: types.GET_DTS_LIST_FAILURE,
		err
	}
}

export function getDtsList() {
	return dispatch => {
		dispatch(requestDtsList())
		return fetch('/ui/dts/list', { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveDtsList(json)))
			.catch(err => dispatch(failedDtsList(err)))
	}
}

export function changeDtsVersion(dtsVersion) {
	return dispatch => {
		dispatch({ type: types.CHANGE_DTS_VERSION, dtsVersion })
		dispatch(getEpList(dtsVersion))
	}
}

export function changeNfoType(nfoType) {
	return {
		type: types.CHANGE_NFO_TYPE,
		nfoType
	}
}

export function changeReportType(reportType) {
	return {
		type: types.CHANGE_REPORT_TYPE,
		reportType
	}
}

export function changePeriodType(periodType) {
	return {
		type: types.CHANGE_PERIOD_TYPE,
		periodType
	}
}

export function changePeriod(date) {
	return {
		type: types.CHANGE_PERIOD,
		date
	}
}

function requestEpList() {
	return {
		type: types.GET_EP_LIST_REQUEST
	}
}

function recieveEpList(list) {
	return {
		type: types.GET_EP_LIST_SUCCESS,
		list
	}
}

function failedEpList(err) {
	return {
		type: types.GET_EP_LIST_FAILURE,
		err
	}
}

export function getEpList(dtsVersion) {
	return dispatch => {
		dispatch(requestEpList())
		return fetch(`/ui/dts/${dtsVersion}/ep/list`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveEpList(json)))
			.catch(err => dispatch(failedEpList(err)))
	}
}

function requestCurrencyList() {
	return {
		type: types.GET_CURRENCY_LIST_REQUEST
	}
}

function recieveCurrencyList(list) {
	return {
		type: types.GET_CURRENCY_LIST_SUCCESS,
		list
	}
}

function failedCurrencyList(err) {
	return {
		type: types.GET_CURRENCY_LIST_FAILURE,
		err
	}
}

export function getCurrencyList() {
	return dispatch => {
		dispatch(requestCurrencyList())
		return fetch(`/ui/currency/list`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveCurrencyList(json)))
			.catch(err => dispatch(failedCurrencyList(err)))
	}
}

export function toggleIsTemporary() {
	return {
		type: types.TOGGLE_IS_TEMPORARY
	}
}

function handleErrors(res, dispatch) {
	if (res.ok) {
		return res
	}

	if (res.status === 401) {
		dispatch(anauthorize())
	}

	throw Error(res.statusText)
}
