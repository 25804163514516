import React from 'react'

const ReadonlyEnumCell = (list, {
  cell: { value: initialValue =  { value: '' } },
  row: { index },
  column: { id }
}) => {
  const selectedItem = list.find((item) => item.key === initialValue.value)
  const dn = selectedItem ? selectedItem.value : initialValue.value

  return <div>{dn}</div>
}

export default ReadonlyEnumCell
