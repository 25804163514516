import './CompanyInfo.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'

const CompanyInfo = ({ name, identifier, onCompanySave }) => {
  const initialValues = {
    name,
    identifier
  }

  const onSubmit = (values) =>  {
    onCompanySave(values.name, values.identifier)
  } 

  return (
    <div className="group company-info">
      <div className="group-header">
        Сведения о компании
      </div>
      <div className="group-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup row className="hidden">
                <Label for="name" sm={2}>Наименование</Label>
                <Col sm={10}>
                  <Input tag={Field} type="text" name="name" placeholder="Наименование" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="identifier" sm={2}>ОГРН</Label>
                <Col sm={10}>
                  <Input tag={Field} type="text" name="identifier" placeholder="ОГРН" bsSize="sm" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={{ size: 10, offset: 2 }}>
                  <Button size="sm">Сохранить</Button>
                </Col>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

CompanyInfo.propTypes = {
  name: PropTypes.string,
  identifier: PropTypes.string.isRequired,
  onCompanySave: PropTypes.func.isRequired,
}

export default CompanyInfo
