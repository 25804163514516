import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reset } from '../../actions/user'
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm'

class ResetPassword extends Component {
  render() {
    const { token, reset } = this.props

    return (
      <ResetPasswordForm
        token={token}
        onReset={reset}
      />
    )
  }
}

ResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
  return {
    token: ownProps.match.params.token
  }
}

export default connect(mapStateToProps, { reset })(ResetPassword)
