import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import moment from 'moment'

registerLocale('ru', ru)

const DateCell = ({
  cell: { value: initialValue = { value: '' } },
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const onFocus = (evt) => {
    if (!evt.target.prevValue) {
      evt.target.prevValue = initialValue.value
    }
  }

  const onChange = (value, evt) => {
    setStartDate(value)

    if (value) {
      value = moment(value).format('YYYY-MM-DD')
    }
    updateMyData(index, id, { value })
    // if (evt.target.prevValue !== date) {
    //   evt.target.prevValue = date
    //   updateMyData(index, id, { date })
    // }
  }

  const m = moment(initialValue.value)
  const date = m.isValid() ? m.toDate() : undefined
  const [startDate, setStartDate] = useState(date)

  return (
    <div className="edit-cell">
      <DatePicker locale='ru' dateFormat='dd.MM.yyyy' selected={startDate} onChange={onChange} />
    </div>
  )
}

export default DateCell
