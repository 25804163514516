import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import Main from '../Main/Main'
import configureStore, { history } from '../../configureStore'

console.log('history', history)

const { store, persistor } = configureStore()

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ConnectedRouter history={history}>
				<Main />
			</ConnectedRouter>
		</PersistGate>
	</Provider>
)

export default App
