import React from 'react'

const ReadonlyCell = ({
  cell: { value: initialValue = { value: '' } },
  row: { index },
  column: { id }
}) => {
  return <div>{ initialValue.value }</div>
}

export default ReadonlyCell
