import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import CheckCbr from '../../components/CheckCbr/CheckCbr'
import CheckFormula from '../../components/CheckFormula/CheckFormula'
import EmptyProject from '../../components/EmptyProject/EmptyProject'
import { fixProject } from '../../actions/project'
import { cbrCheckOkud, formulaCheckOkud } from '../../actions/okud'
import './OkudCheck.css'

class OkudCheck extends Component {
  async componentDidMount() {
    await this.props.cbrCheckOkud(this.props.id)

    this.props.formulaCheckOkud(this.props.id)
  }

  render() {
    let { cbrCheckLog, formulaCheckLog, isFormulaProcessed } = this.props
    // todo: rm
    cbrCheckLog = cbrCheckLog || []
    formulaCheckLog = formulaCheckLog || []
    formulaCheckLog = formulaCheckLog.filter((item) => !item.err)

    const errCount = cbrCheckLog.reduce((count, errGroup) => {
      return count + errGroup.entries.length
    }, formulaCheckLog.length)

    return (
      <div className="container">
        <h1 className="step-header">Замечания</h1>

        { isFormulaProcessed && this.renderContextLoader() }

        <div className="bg_white">
          <div className="check-summary">
            <h3 className="check-summary-text">
              <p>Найдено {errCount} замечаний</p>
              {/*<p>Технические ошибки могут быть автоматически исправлены</p>*/}
            </h3>
            {/*<Button className="button flex" onClick={this.fix.bind(this)}>Исправить</Button>*/}
          </div>
        </div>

        { cbrCheckLog && cbrCheckLog.map((entry) => <CheckCbr entry={entry} />) }

        { formulaCheckLog && formulaCheckLog.map((entry) => <CheckFormula entry={entry} />) }
      </div>
    )
  }

  fix() {
    const { fixProject, id } = this.props
    fixProject(id)
  }

  renderContextLoader() {
    return (
      <div className="context-loader">
        <span><i className="fas fa-spinner fa-spin"></i></span>
        Идет проверка контрольных соотношений. Обычно это занимает несколько минут.
      </div>
    )
  }
}

OkudCheck.propTypes = {
  id: PropTypes.string,
  cbrCheckLog: PropTypes.array.isRequired,
  cbrCheckOkud: PropTypes.func.isRequired,
  formulaCheckOkud: PropTypes.func.isRequired,
  formulaCheckLog: PropTypes.array.isRequired,
  isFormulaProcessed: PropTypes.bool
}

function mapStateToProps(state, ownProps) {
  return {
    id: state.okud.id || ownProps.match.params.okudId,
    cbrCheckLog: state.okud.cbrCheckLog,
    formulaCheckLog: state.okud.formulaCheckLog,
    isFormulaProcessed: state.loader.isFormulaProcessed
  }
}

export default connect(mapStateToProps, { cbrCheckOkud, formulaCheckOkud, fixProject })(OkudCheck)
