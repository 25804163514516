import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckEntry from '../CheckEntry/CheckEntry'
import './CheckFormula.css'

class CheckFormula extends CheckEntry {
  render() {
    const formulaEntry = this.props.entry
    const entry = {
      dn: 'Ошибка контрольных соотношений',
      descr: formulaEntry.msg,
      details: this.renderDetails(formulaEntry)
    }

    return (
      <CheckEntry entry={entry} />
    )
  }

  renderDetails(entry) {
    return (
      <div>
        <div className="check-entry-formula-expression">
          {entry.expression}
        </div>
        <ul className="check-entry-formula-variables">
          { entry.variables.map((v) => <li>{v.name}: {Array.isArray(v.value) ? v.value.join(' ') : v.value}</li>) }
        </ul>
      </div>
    )
  }
}

CheckFormula.propTypes = {
  entry: PropTypes.shape({
    descr: PropTypes.string.isRequired,
    expression: PropTypes.string.isRequired,
    variables: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired
  }).isRequired
}

export default CheckFormula