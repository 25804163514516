import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTable } from 'react-table'
import { Row, Col } from 'reactstrap'
import Moment from 'react-moment'
import EditTable from '../../components/EditTable/EditTable'
import EditorPanel from '../../components/EditorPanel/EditorPanel'
import ProjectIndex from '../../components/ProjectIndex/ProjectIndex'
import {
  updateCell,
  addRow,
  removeRow,
  draftApply, 
  draftDrop 
} from '../../actions/editor'
import { getView } from '../../actions/project'
import { getCurrencyList } from '../../actions/ui'
import './ProjectEdit.css'

class ProjectEdit extends Component {
  componentDidMount() {
    if (!this.props.view) {
      this.props.getView(this.props.id)
    }

    this.props.getCurrencyList()
  }

  render() {
    let { id, readOnly, view, selectedOkud, selectedTable, lastSaveTime, isSaving } = this.props

    const title = readOnly ? 'Просмотр' : 'Редактирование' 

    const okudList = this.buildOkudList(view)

    // if (!selectedOkud) {
    //   selectedOkud = okudList.length ? okudList[0].code : null
    // }
    // if (!selectedTable && selectedOkud) {
    //   const okud = okudList.find((okud) => okud.code === selectedOkud)
    //   selectedTable = okud ? okud.tableList[0].code : null
    // }

    return (
      <div className="view-container">
        <div className="last-save-time">
          {
            isSaving && 
              <div>
                <i className="fas fa-sm fa-spinner fa-spin saving-spinner"></i>
                Сохранение...
              </div>
          }
          {
            lastSaveTime && !isSaving &&
              <div>
                Сохранено <Moment format="DD.MM.YYYY HH:mm">{lastSaveTime}</Moment>
              </div>
          }
        </div>
        
        <h1 className="step-header">{title}</h1>

        <Row>
          <Col sm="1">
            <ProjectIndex
              okudList={okudList}
              selectedOkud={selectedOkud}
              selectedTable={selectedTable}
            />
          </Col>

          <Col sm="11" className="tables-container">
            { this.renderViewModel(view, selectedOkud, selectedTable) }
          </Col>
        </Row>
      </div>
    )
  }

  buildOkudList(model) {
    if (!model) return [] // todo:

    return [...Object.keys(model)].map((okud) => {
      return {
        code: okud,
        name: model[okud].label,
        tableList: model[okud]._grids.map((grid) => {
          return {
            code: grid.tag,
            name: grid.prettyLabel
          }
        })
      }
    })
  }

  renderViewModel(model, selectedOkud, selectedTable) {
    const projectId = this.props.id

    let res = []
    for (let okud in model) {
      if (selectedOkud && okud !== selectedOkud) continue

      const okudProps = model[okud]._properties

      const tables = model[okud]._grids
        .filter((grid) => {
          return !selectedTable || grid.tag === selectedTable
        })
        .map((grid) => {
          const id = `${okud}-${grid.tag}`
          const onDataChange = this.props.updateCell.bind(this, projectId, okud, grid.tag)
          const onAddRow = this.props.addRow.bind(this, projectId, okud, grid.tag)
          const onRowRemove = this.props.removeRow.bind(this, projectId, okud, grid.tag)
          return (
            <EditTable 
              key={id}
              id={id}
              model={grid}
              currencyList={this.props.currencyList}
              defaultCurrency={okudProps.defaultCurrency}
              defaultDecimals={okudProps.defaultDecimals}
              editable={!this.props.readOnly} 
              onDataChange={onDataChange}
              onRowAdd={onAddRow}
              onRowRemove={onRowRemove}
            />
          )
        })

      res = res.concat(tables)
    }

    return res
  }
}

ProjectEdit.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  view: PropTypes.object,
  selectedOkud: PropTypes.string,
  selectedTable: PropTypes.string,
  lastSaveTime: PropTypes.instanceOf(Date).isRequired,
  isSaving: PropTypes.bool.isRequired,
  getView: PropTypes.func.isRequired,
  updateCell: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  draftApply: PropTypes.func.isRequired,
  draftDrop: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
}

function mapStateToProps(state, ownProps) {
  const { id, view, readOnly, lastSaveTime, isSaving } = state.project

  return {
    id: id || ownProps.match.params.projectId,
    selectedOkud: ownProps.match.params.okud,
    selectedTable: ownProps.match.params.table,
    currencyList: state.newp.currencyList,
    view,
    readOnly,
    lastSaveTime,
    isSaving
  }
}

export default connect(mapStateToProps, {
  getView,
  updateCell, 
  addRow,
  removeRow,
  draftApply, 
  draftDrop,
  getCurrencyList
})(ProjectEdit)
