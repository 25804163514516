import { combineReducers } from 'redux'
import produce from 'immer'
import * as types from '../actions/types'

const defaultState = {
	dtsList: [],
  nfoList: [],
  reportTypeList: [],
  periodTypeList: [],
  epList: [], // todo: rm
  currencyList: [],

  baseProjectId: null,
  dtsVersionCode: null,
  nfoTypeCode: null,
  reportTypeCode: null,
  periodTypeCode: null,
  periodEnd: null,
  ep: null,
  currency: null,
  decimals: 2,
  isTemporary: false
}

export default function (state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CHANGE_DTS_VERSION:
        draft.dtsVersionCode = action.dtsVersion

        draft.nfoTypeCode = null
        draft.nfoList = []

        draft.reportTypeCode = null
        draft.reportTypeList = []

        draft.periodTypeCode = null
        draft.periodTypeList = []

        draft.ep = null
        draft.periodEnd = null
        break

      case types.CHANGE_NFO_TYPE:
        draft.nfoTypeCode = action.nfoType

        draft.reportTypeCode = null
        draft.reportTypeList = findReportTypeList(draft.epList, draft.nfoTypeCode)

        draft.periodTypeCode = null
        draft.periodTypeList = []

        draft.ep = null
        draft.periodEnd = null
        break

      case types.CHANGE_REPORT_TYPE:
        draft.reportTypeCode = action.reportType

        draft.periodTypeCode = null
        draft.periodTypeList = findPeriodTypeList(draft.epList, draft.nfoTypeCode, draft.reportTypeCode)
        
        draft.ep = null
        draft.periodEnd = null
        break

      case types.CHANGE_PERIOD_TYPE:
        draft.periodTypeCode = action.periodType

        const ep = draft.epList.find((ep) => {
          return ep.descr.nfoType.code === draft.nfoTypeCode &&
            ep.descr.reportType.code === draft.reportTypeCode &&
            ep.descr.periodType.code === draft.periodTypeCode
        })
        if (ep) {
          draft.ep = ep.ns
        }

        draft.periodEnd = null
        break

      case types.CHANGE_PERIOD:
        draft.periodEnd = action.date
        break

      case types.GET_DTS_LIST_SUCCESS:
        draft.dtsList = action.list
        break

      case types.GET_EP_LIST_SUCCESS:
        draft.epList = action.list

        draft.nfoTypeCode = null
        draft.nfoList = findNfoList(draft.epList)
        break

      case types.GET_CURRENCY_LIST_SUCCESS:
        draft.currencyList = action.list
        break

      case types.SELECT_BASE_PROJECT:
        draft.baseProjectId = action.id
        
        const i = action.info

        draft.dtsVersionCode = i.dtsVersionCode
        draft.nfoTypeCode = i.nfoTypeCode

        draft.reportTypeList = findReportTypeList(draft.epList, draft.nfoTypeCode)
        draft.reportTypeCode = i.reportTypeCode

        draft.periodTypeList = findPeriodTypeList(draft.epList, draft.nfoTypeCode, draft.reportTypeCode)
        draft.periodTypeCode = i.periodTypeCode

        draft.periodEnd = i.periodEnd
        draft.ep = i.ep.replace(/\.xsd$/, '')

        draft.currency = i.currency
        draft.decimals = i.decimals
        break

      case types.TOGGLE_IS_TEMPORARY:
        draft.isTemporary = !draft.isTemporary
        break

      case types.CREATE_PROJECT_SUCCESS:
      case types.LOGOUT_USER_SUCCESS:
        return defaultState

      default:
        break
    }
  })
}

function findNfoList(epList) {
  return epList.reduce((res, ep) => {
    if (!res.find((item) => item.code === ep.descr.nfoType.code)) {
      res.push({
        code: ep.descr.nfoType.code,
        name: ep.descr.nfoType.name
      })
    }

    return res
  }, [])
}

function findReportTypeList(epList, nfoTypeCode) {
  return epList.reduce((res, ep) => {
    if (ep.descr.nfoType.code !== nfoTypeCode) return res

    if (!res.find((item) => item.code === ep.descr.reportType.code)) {
      res.push({
        code: ep.descr.reportType.code,
        name: ep.descr.reportType.name
      })
    }

    return res
  }, [])
}

function findPeriodTypeList(epList, nfoTypeCode, reportTypeCode) {
  return epList.reduce((res, ep) => {
    if (ep.descr.nfoType.code !== nfoTypeCode || ep.descr.reportType.code !== reportTypeCode) return res

    if (!res.find((item) => item.code === ep.descr.periodType.code)) {
      res.push({
        code: ep.descr.periodType.code,
        name: ep.descr.periodType.name
      })
    }

    return res
  }, [])
}
