import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTable } from 'react-table'
import { Row, Col } from 'reactstrap'
import Moment from 'react-moment'
import { NavLink } from 'react-router-dom'
import EditTable from '../../components/EditTable/EditTable'
import EditorPanel from '../../components/EditorPanel/EditorPanel'
import ProjectIndex from '../../components/ProjectIndex/ProjectIndex'
import {
  updateCell,
  addRow,
  copyRow,
  removeRow,
  draftApply, 
  draftDrop 
} from '../../actions/editor'
import { getView, getExcel } from '../../actions/okud'
import { getCurrencyList } from '../../actions/ui'
/* eslint import/no-webpack-loader-syntax: off */
import tableStyles from '!!raw-loader!../../components/EditTable/EditTable.css'

class OkudEdit extends Component {
  componentDidMount() {
    this.props.getView(this.props.id)

    this.props.getCurrencyList()
  }

  render() {
    const onExportClick = (evt) => {
      const tables = document.getElementById('tables-container')

      const html = `
        <html>
          <head>
            <style>${tableStyles}</style>
          </head>
          ${tables.outerHTML}
        </html>
      `

      const tempEl = document.createElement('a')
      tempEl.href = 'data:attachment/html,' + encodeURIComponent(html)
      tempEl.target = '_blank'
      tempEl.download = `${this.props.code}.html`
      tempEl.click()
    }

    let { id, readOnly, view, selectedTable, getExcel, lastSaveTime, isSaving, comment } = this.props

    const title = readOnly ? 'Просмотр' : 'Редактирование' 

    const tableList = this.buildTableList(view)

    console.time('build')
    const res = (
      <div className="view-container">
        <div className="last-save-time">
          {
            isSaving && 
              <div>
                <i className="fas fa-sm fa-spinner fa-spin saving-spinner"></i>
                Сохранение...
              </div>
          }
          {
            lastSaveTime && !isSaving &&
              <div>
                Сохранено <Moment format="DD.MM.YYYY HH:mm">{lastSaveTime}</Moment>
              </div>
          }
        </div>

        {
          comment &&
          <div className="project-comment">
            <span>Комментарий</span>
            <p>{comment}</p>
          </div>
        }

        <h1 className="step-header">
          {title}
          <div className="step-header-actions">
            <NavLink to='#' title="Выгрузить отчетную форму в Excel" onClick={getExcel.bind(this, id)}>Excel</NavLink>
            <NavLink to='#' title="Скачать печатный вид отчетной формы" onClick={onExportClick}>Печатная форма</NavLink>
          </div>
        </h1>

        <Row>
          <Col sm="1">
            <ProjectIndex
              tableList={tableList}
              selectedTable={selectedTable}
            />
          </Col>

          <Col sm="11" id="tables-container" className="tables-container">
            { this.renderViewModel(view, selectedTable) }
          </Col>
        </Row>
      </div>
    )

    console.timeEnd('build')

    return res
  }

  buildTableList(model) {
    if (!model) return [] // todo:

    return model._grids.map((grid) => {
      return {
        code: grid.tag,
        name: grid.prettyLabel
      }
    })

    return {
      code: 'okud',
      name: 'label',
      tableList: model._grids.map((grid) => {
        return {
          code: grid.tag,
          name: grid.prettyLabel
        }
      })
    }
  }

  renderViewModel(model, selectedTable) {
    if (!model) return [] // todo:

    const okudId = this.props.id

    return model._grids
      .filter((grid) => {
        return !selectedTable || grid.tag === selectedTable
      })
      .map((grid) => {
        const id = grid.tag
        const onDataChange = this.props.updateCell.bind(this, okudId, grid.tag)
        const onRowAdd = this.props.addRow.bind(this, okudId, grid.tag)
        const onRowCopy = this.props.copyRow.bind(this, okudId, grid.tag)
        const onRowRemove = this.props.removeRow.bind(this, okudId, grid.tag)
        return (
          <EditTable 
            key={id}
            id={id}
            model={grid}
            currencyList={this.props.currencyList}
            defaultCurrency={model._properties.defaultCurrency}
            defaultDecimals={model._properties.defaultDecimals}
            editable={!this.props.readOnly} 
            onDataChange={onDataChange}
            onRowAdd={onRowAdd}
            onRowCopy={onRowCopy}
            onRowRemove={onRowRemove}
          />
        )
      })
  }
}

OkudEdit.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  view: PropTypes.object,
  selectedTable: PropTypes.string,
  lastSaveTime: PropTypes.instanceOf(Date).isRequired,
  isSaving: PropTypes.bool.isRequired,
  getView: PropTypes.func.isRequired,
  getExcel: PropTypes.func.isRequired,
  updateCell: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  copyRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  draftApply: PropTypes.func.isRequired,
  draftDrop: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.okudId
  const { code, view, lastSaveTime, isSaving } = state.okud

  const okud = state.project.okuds.find((okud) => okud._id === id)
  const readOnly = okud ? !okud.permissions.canEdit : true
  const comment = state.project.info? state.project.info.comment : null

  return {
    id,
    code,
    selectedTable: ownProps.match.params.table,
    currencyList: state.newp.currencyList,
    readOnly,
    view,
    lastSaveTime,
    isSaving,
    comment
  }
}

export default connect(mapStateToProps, {
  getView,
  getExcel,
  updateCell, 
  addRow,
  copyRow,
  removeRow,
  draftApply, 
  draftDrop,
  getCurrencyList
})(OkudEdit)
