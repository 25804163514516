import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { useTable } from 'react-table'
import DateCell from './DateCell/DateCell.jsx'
import NumericCell from './NumericCell/NumericCell.jsx'
import EditableCell from './EditableCell/EditableCell.jsx'
import EnumCell from './EnumCell/EnumCell.jsx'
import EnumCellMultiSelect from './EnumCell/EnumCellMultiSelect.jsx'
import ReadonlyEnumCell from './EnumCell/ReadonlyEnumCell.jsx'
import ReadonlyCell from './ReadonlyCell/ReadonlyCell.jsx'
import DisabledCell from './DisabledCell/DisabledCell.jsx'
import './EditTable.css'

function Table({ label, columns, data, btnAddRow, updateMyData, editable, currencyList, onRowAdd, onRowCopy, onRowRemove }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    updateMyData
  })

  return (
    <div>
      <h5 className='view-table-title'>{label}</h5>

      <table {...getTableProps()} className='view-table'>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <th {...column.getHeaderProps()}>
                  <div title="Добавить строку">
                    {column.render('Header')}
                    {
                      btnAddRow && i === headerGroups.length - 1 && j === 0 &&
                      <Button color="link" className="btn-add-row" onClick={onRowAdd.bind(this, 0)}>Добавить строку</Button>
                    }
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)

            const uuid = row.original.uuid

            return (
              <tr /*{...row.getRowProps()}*/ key={uuid}>
                {row.cells.map((cell, j) => {
                  
                  cell.column.Cell = getCellComponent(row.original, cell, editable, currencyList)

                  return (
                    <td {...cell.getCellProps()}>
                      { 
                        editable && j === 0 && row.original.unbounded && row.original.index === 0 && 
                        <div>
                          <i class="far fa-clone btn-copy-row" title='Копировать строку' onClick={() => onRowCopy(i)}></i>
                          <i class="fas fa-times btn-rm-row" title='Удалить строку' onClick={() => onRowRemove(i)}></i>
                        </div>
                      }
                      {  cell.render('Cell')}
                      { 
                        row.original.btnAddRow && j === 0 &&
                        <Button color="link" className="btn-add-row" onClick={onRowAdd.bind(this, i + 1)}>Добавить строку</Button>
                      }
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

class EditTable extends Component {
  render() {
    const { id, model, defaultCurrency, currencyList, defaultDecimals, onDataChange, editable, onRowAdd, onRowCopy, onRowRemove } = this.props

    const data = this.makeData(model, defaultCurrency, defaultDecimals)
    const cols = this.makeColumns(model)

    return (
      <div id={id} data-key={id} className="view-table-wrap">
        <Table
          label={model.prettyLabel}
          columns={cols}
          data={data}
          btnAddRow={model.btnAddRow}
          editable={editable}
          currencyList={currencyList}
          updateMyData={onDataChange.bind(this)}
          onRowAdd={onRowAdd.bind(this)}
          onRowCopy={onRowCopy.bind(this)}
          onRowRemove={onRowRemove.bind(this)}
        />
      </div>
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    const currModel = this.props.model
    const nextModel = nextProps.model

    return (currModel._rows.length !== nextModel._rows.length) ||
      (this.props.editable !== nextProps.editable)
  }

  makeData(model, defaultCurrency, defaultDecimals) {
    const rows = model._rows.map((row) => {
      const res = {
        uuid: row.uuid,
        unbounded: row.unbounded,
        size: row.size,
        index: row.index,
        btnAddRow: row.btnAddRow,
        type: row.type,
        list: row.list
      }

      for (let [i, cell] of row._cells.entries()) {
        res[cell.id] = {
          value: isNullOrUndefined(cell.data) ? '' : cell.data,
          decimals: isNullOrUndefined(cell.decimals) ? defaultDecimals : cell.decimals,
          currency: isNullOrUndefined(cell.currency) ? defaultCurrency : cell.currency,
          disabled: cell.type === 'disabled'
        }
      }

      return res
    })

    console.log('rows', rows)
    return rows
  }

  makeColumns(model) {
    // Искусственно вставляем пустой столбрец для управляющих элементов
    const sysCol = [{ accessor: 'h0' }]
    const cols = model._treeHeaders.map((header) => this.processColumn(header))

    return sysCol.concat(cols)
  }

  processColumn(header) {
    const col = {
      accessor: header.id,
      Header: header.label || ' ',
      type: header.type,
      list: header.list 
    }

    // col.Cell = this.getCellComponent(header)

    for (let child of header.children) {
      col.columns = col.columns || []
      col.columns.push(this.processColumn(child))  
    }

    return col
  }
}

function isNullOrUndefined(value) {
  return value === null || value === undefined
}

function getCellComponent(row, cell, editable, currencyList) {
  const disabled = !row.unbounded && (!cell.value || cell.value.disabled)
  if (disabled) {
    return DisabledCell
  }

  if (!cell.column.id || cell.column.id.startsWith('h')) {
    return ReadonlyCell
  }

  let type
  let list

  if (cell.column.id.startsWith('d') || !row.type) {
    type = cell.column.type
    list = cell.column.list
  } else {
    type = row.type
    list = row.list
  }
  
  if (!editable) {
    return type === 'enum' ? ReadonlyEnumCell.bind(this, list) : ReadonlyCell
  }

  switch (type) {
    case 'enum':
      return EnumCell.bind(this, list)
    case 'enum2':
      return EnumCellMultiSelect.bind(this, list)
    case 'decimal':
      return NumericCell.bind(this, { pattern: '0,0.[00000000000000000000]' })
    case 'int':
      return NumericCell.bind(this, { pattern: '0,', roundFn: Math.floor })
    case 'monetary':
      return NumericCell.bind(this, { pattern: '0,0.[00000000000000000000]', currencyList })
    case 'date':
      return DateCell
    case 'dateTime':
      return DateCell
    default:
      return EditableCell
  }
}

EditTable.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  defaultDecimals: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  onDataChange: PropTypes.func.isRequired,
  onRowAdd: PropTypes.func.isRequired,
  onRowCopy: PropTypes.func.isRequired,
  onRowRemove: PropTypes.func.isRequired
}


export default EditTable
