import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import '../AuthForm/AuthForm.css'

const ResetPasswordForm = ({ token, onReset }) => {
  const onSubmit = (values) =>  {
    if (values.password !== values.password2) {
      return alert('Пароли должны совпадать')
    }

    onReset(values.password, token)
  }

  return (
    <div className="auth-container">
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <h2>Смена пароля</h2>
            <FormGroup>
              <Label for="password">Пароль</Label>
              <Input tag={Field} type="password" name="password" placeholder="Пароль" />
            </FormGroup>
            <FormGroup>
              <Label for="password2">Повторите пароль</Label>
              <Input tag={Field} type="password" name="password2" placeholder="Повторите пароль" />
            </FormGroup>
            <Button size="sm">Сохранить</Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired
}

export default ResetPasswordForm
