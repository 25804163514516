import React, { useState } from 'react'
import PropTypes from 'prop-types'

const OnDemandSelect = ({ list, nameField, valueField, resultField, initialValue, onSelect, divModeClassName, listModeClassName }) => {
  const [isListMode, setListMode] = useState(false)
  const [value, setValue] = useState(initialValue)

  const onFocus = (evt) => {
    setListMode(true)
  }

  const onBlur = (evt) => {
    setListMode(false)
  }

  const onChange = (evt) => {
    const value = evt.target.value
    if (evt.target.prevValue !== value) {
      evt.target.prevValue = value
      
      const res = {}
      res[resultField] = value
      onSelect(res)
    }

    setValue(value)
    setListMode(false)
  }

  const selectedItem = list.find((item) => item[valueField] === value)
  const dn = selectedItem ? selectedItem[nameField] : value

  if (isListMode) {
    return (
      <select className={listModeClassName} onBlur={onBlur} onChange={onChange}>
        <option></option>
        {
          list.map((item) => <option selected={item === selectedItem} value={item[valueField]}>{item[nameField]}</option>)
        }
      </select>
    )
  } else {
    return (
      <div 
        className={divModeClassName}
        contentEditable
        suppressContentEditableWarning 
        onFocus={onFocus}>
          {dn}
      </div>
    )
  }
}

OnDemandSelect.propTypes = {
  list: PropTypes.array.isRequired,
  nameField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  resultField: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  divModeClassName: PropTypes.string,
  listModeClassName: PropTypes.string
}

export default OnDemandSelect
