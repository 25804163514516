import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProjectListComp from '../../components/ProjectList/ProjectList'
import ProjectFilter from '../../components/ProjectFilter/ProjectFilter'
import { getProjects, changeFilterParams } from '../../actions/projects'
import {
	removeProject,
	archiveProject,
	restoreProject
} from '../../actions/project'

class ProjectList extends Component {

	componentDidMount() {
		const { projects, getProjects } = this.props

		if (!projects.list.length) {
			getProjects(0, 0, projects.filters)
		}
	}

	onFilterChange = (params) => {
		const { changeFilterParams } = this.props
		changeFilterParams(0, 0, params)
	}

	render() {
		const { projects, getProjects, removeProject, archiveProject, restoreProject } = this.props

		return (
			<div className="container">
				<h1 className="step-header">Ваши отчеты</h1>
				<ProjectFilter
					filters={projects.filters}
					onFilterChange={this.onFilterChange}
					/>
				<ProjectListComp
					projects={projects.list}
					fetchPage={getProjects.bind(this, projects.page, projects.offset, projects.filters)}
					removeProject={removeProject}
					archiveProject={archiveProject}
					restoreProject={restoreProject}
				/>
			</div>
		)
	}
}

ProjectList.propTypes = {
	projects: PropTypes.arrayOf(PropTypes.shape({
	    id: PropTypes.string.isRequired,
	    assigneeId: PropTypes.string,
	    assigneeName: PropTypes.string,
	    info: PropTypes.object.isRequired,
	    aspects: PropTypes.arrayOf(PropTypes.shape({
	    	code: PropTypes.string.isRequired,
	    	name: PropTypes.string,
	    	values: PropTypes.arrayOf(PropTypes.string)
	    })),
	    status: PropTypes.string,
	    permissions: PropTypes.object,
	    filters: PropTypes.shape({
			archive: PropTypes.bool
		})
	})).isRequired,

	removeProject: PropTypes.func.isRequired,
	archiveProject: PropTypes.func.isRequired,
	restoreProject: PropTypes.func.isRequired,
	changeFilterParams: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
	const { projects } = state
	return {
		projects
	}
}

export default connect(mapStateToProps, { getProjects, changeFilterParams, removeProject, archiveProject, restoreProject })(ProjectList)
