import { combineReducers } from 'redux'
import produce from 'immer'
import * as types from '../actions/types'

const defaultState = {
  id: null,
  assigneeId: null,
  comment: null,
  status: null,
  regNum: null,
  regDate: null,
  regUrl: null,
  permissions: {},
  info: null,
  readOnly: false,
  okuds: [],
  struct: null,
  files:[],
  uploads: [],
  cbrCheckLog: null,
  formulaCheckLog: null,

  okudCodeForAdd: null,
  okudIdForEdit: null,
}

export default function (state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATE_PROJECT_SUCCESS:
        draft.id = action.id
        break

      case types.FILE_UPLOAD_COMPLETE:
        const res = action.payload.response
        if (res.uploads) {
          draft.uploads = draft.uploads.concat(res.uploads)
        } else {
          draft.uploads = []
        }

        if (res.okuds) {
          draft.okuds = res.okuds
        }
        if (res.files) {
          draft.files = draft.files.concat(res.files)
        }
        break

      case types.PROJECT_CLEAR_UPLOAD_ERRORS:
        draft.uploads = []
        break
      
      case types.GET_PROJECT_SUCCESS:
        const p = action.project
        draft.id = p.id
        draft.assigneeId = p.assigneeId
        draft.status = p.status
        draft.regNum = p.regNum
        draft.regDate = p.regDate
        draft.regUrl = p.regUrl
        draft.permissions = p.permissions
        draft.readOnly = p.readOnly
        draft.info = p.info
        draft.struct = p.struct
        draft.okuds = p.okuds
        draft.files = p.files
        break

      case types.CHANGE_PROJECT:
        draft.id = action.id

        draft.info = null
        draft.view = null
        draft.cbrCheckLog = null
        draft.formulaCheckLog = null
        draft.files = []
        break

      case types.PROJECT_SET_OKUD_CODE_FOR_ADD:
        draft.okudCodeForAdd = action.code
        break

      case types.PROJECT_RESET_OKUD_CODE_FOR_ADD:
        draft.okudCodeForAdd = null
        break

      case types.PROJECT_SET_OKUD_ID_FOR_EDIT:
        draft.okudIdForEdit = action.id
        break

      case types.PROJECT_RESET_OKUD_ID_FOR_EDIT:
        draft.okudIdForEdit = null
        break

      case types.OKUD_ADD_SUCCESS:
        draft.okuds.push(action.okud)
        break

      case types.OKUD_EDIT_SUCCESS:
        let index = draft.okuds.findIndex((okud) => okud._id === action.okud._id)
        draft.okuds.splice(index, 1, action.okud)
        break

      case types.OKUD_REMOVE_SUCCESS:
        index = draft.okuds.findIndex((okud) => okud._id === action.id)
        if (index > -1) {
          draft.okuds.splice(index, 1)
        }
        break

      case types.OKUD_ASSIGN_USER_SUCCESS:
        index = draft.okuds.findIndex((okud) => okud._id === action.okudId)
        if (index > -1) {
          draft.okuds[index].assigneeId = action.assigneeId
          draft.okuds[index].permissions = action.permissions
          draft.okuds[index].status = action.status
          draft.status = action.status
        }
        break

      case types.OKUD_UNASSIGN_USER_SUCCESS:
        index = draft.okuds.findIndex((okud) => okud._id === action.okudId)
        if (index > -1) {
          draft.okuds[index].assigneeId = null
          draft.okuds[index].permissions = action.permissions
          draft.okuds[index].status = action.status
          draft.status = draft.okuds.some((okud) => okud.status === 'inprogress') ? 'inprogress' : null
        }
        break

      case types.OKUD_SET_STATUS_SUCCESS:
        index = draft.okuds.findIndex((okud) => okud._id === action.okudId)
        if (index > -1) {
          draft.okuds[index].status = action.status
          draft.okuds[index].permissions = action.permissions
        }
        if (action.status === 'inprogress') {
          draft.status = 'inprogress'
        } else {
          draft.status = draft.okuds.some((okud) => okud.status === 'inprogress') ? 'inprogress' : null
        }
        break

      case types.FILE_REMOVE_SUCCESS:
        index = draft.files.findIndex((file) => file.id === action.id)
        if (index > -1) {
          draft.files.splice(index, 1)
        }
        break

      case types.PROJECT_ASSIGN_USER_SUCCESS:
        draft.assigneeId = action.assigneeId
        break

      case types.PROJECT_UNASSIGN_USER_SUCCESS:
        draft.assigneeId = null
        break

      case types.PROJECT_SET_REG_INFO_SUCCESS:
        draft.regNum = action.regNum
        draft.regDate = action.regDate
        draft.regUrl = action.regUrl
        draft.okuds.forEach((okud) => okud.permissions.canEdit = false)
        break

      case types.PROJECT_SET_COMMENT_SUCCESS:
        draft.info.comment = action.comment
        break

      case types.PROJECT_RO_SUCCESS:
        draft.readOnly = action.readOnly
        break
      
      case types.CBR_CHECK_SUCCESS:
        draft.cbrCheckLog = action.cbrCheckLog
        break

      case types.FIX_SUCCESS:
        draft.cbrCheckLog = []
        break

      case types.FORMULA_CHECK_SUCCESS:
        draft.formulaCheckLog = action.formulaCheckLog || []
        break

      case types.LOGOUT_USER_SUCCESS:
        return defaultState
      
      default:
        return draft
    }
  })
}
