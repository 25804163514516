import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDom from 'react-dom'
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap'
import './SignUp.css'

class SignUp extends Component {
  constructor(props) {
    super(props)

    this.name = React.createRef()
    this.email = React.createRef()
    this.identifier = React.createRef()
  }

  handleSubmit(evt) {
    evt.preventDefault()

    const name = ReactDom.findDOMNode(this.name.current).value
    const email = ReactDom.findDOMNode(this.email.current).value
    const identifier = ReactDom.findDOMNode(this.identifier.current).value

    this.props.handler(name, email, null, identifier)
  }

  render() {
    return (
      <div>
        <Form className="form-signup" onSubmit={this.handleSubmit.bind(this)}>
          <h2>Регистрация</h2>
          <FormGroup>
            <Label for="nameSignUp">Отображаемое имя</Label>
            <Input type="text" name="name" id="nameSignUp" ref={this.name} placeholder="Иванов Иван" />
          </FormGroup>
          <FormGroup>
            <Label for="emailSignUp">Email адрес</Label>
            <Input type="email" name="email" id="emailSignUp" ref={this.email} placeholder="user@company.ru" />
          </FormGroup>
          <FormGroup>
            <Label for="identifierSignUp">Идентификатор организации</Label>
            <Input type="text" name="identifier" id="identifierSignUp" ref={this.identifier} placeholder="ОГРН" />
          </FormGroup>
          
          <Button color="primary">Зарегистрироваться</Button>
        </Form>
      </div>
    )
  }
}

SignUp.propTypes = {
  handler: PropTypes.func.isRequired
}

export default SignUp
