import './Company.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo'
import CompanyUserList from '../../components/CompanyUserList/CompanyUserList'
import CompanyFundList from '../../components/CompanyFundList/CompanyFundList'
import { getCompany, setCompany, inviteUser, addFund, editFund, deleteFund, getFundTypes } from '../../actions/company'

class Company extends Component {
  
  async componentDidMount() {
    if (!this.props.identifier) {
      await this.props.getFundTypes()
      this.props.getCompany()
    }
  }

  render() {
    const { name, identifier, users, funds, setCompany, inviteUser, addFund, editFund, deleteFund, fundTypes } = this.props

    if (!identifier) return null

    return (
      <div className="container company">
        <h1 className="step-header">Управление организацией</h1>

        <CompanyInfo
          name={name}
          identifier={identifier}
          onCompanySave={setCompany} />

        <CompanyUserList
          users={users}
          onUserInvite={inviteUser} />

        <CompanyFundList
          funds={funds}
          fundTypes={fundTypes}
          onAddFund={addFund}
          onEditFund={editFund}
          onDeleteFund={deleteFund} />
      </div>
    )
  }
}

Company.propTypes = {
  name: PropTypes.string,
  identifier: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  funds: PropTypes.arrayOf(PropTypes.object).isRequired
}

function mapStateToProps(state, ownProps) {
  return state.company
}

export default connect(mapStateToProps, {
  getCompany,
  setCompany,
  inviteUser,
  addFund,
  editFund,
  deleteFund,
  getFundTypes
})(Company)
