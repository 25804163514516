import React from 'react'
import PropTypes from 'prop-types'
import { Nav, NavItem, FormGroup, Label, Col } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FileUpload } from 'redux-file-upload'
import UserSelect from '../UserSelect/UserSelect'
import ProjectStatus from '../ProjectStatus/ProjectStatus'
import './ProjectActions.css'

const ProjectActions = ({ id, readOnly, assigneeId, permissions, status, users, assignUser, unAssignUser, onDownload, regNum, regDate, regUrl, setRegInfo, onBuildPackage }) => {
  const onDownloadClick = (evt) => {
    evt.preventDefault()
    onDownload.call(this, id)
  }

  const onBuildPackageClick = (evt) => {
    evt.preventDefault()
    onBuildPackage.call(this, id)
  }

  return (
    <div>
      <div className='project-actions'>
        <Nav>
          <NavItem>
            <FileUpload
              allowedFileTypes={['xml', 'xbrl']}
              multiple={true}
              dropzoneId="dropZone"
              url={`/api/project/${id}/upload`}
            >
              <NavLink to='#'>Импорт</NavLink>
            </FileUpload>
          </NavItem>
          <NavItem>
            <NavLink to={`/project/${id}/check`}>Замечания</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='#' onClick={onDownloadClick}>Скачать xbrl файл</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='#' onClick={onBuildPackageClick}>Скачать пакет отчетности</NavLink>
          </NavItem>
        </Nav>
      </div>

      <div className="project-assignee">
        <FormGroup row>
          <Label sm={2}>Исполнитель</Label>
          <Col sm={4}>
            <UserSelect
              users={users}
              selectedId={assigneeId}
              disabled={!permissions.canAssign}
              onChange={assignUser.bind(this, id)}
              onRemove={unAssignUser.bind(this, id)}
            />
          </Col>
          <Col sm={{ size: 5, offset: 1 }}>
            <ProjectStatus
              status={status}
              regNum={regNum}
              regDate={regDate}
              regUrl={regUrl}
              setRegInfo={setRegInfo}
            />
          </Col>
        </FormGroup>
      </div>
    </div>
  )
}

ProjectActions.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  assigneeId: PropTypes.string,
  permissions: PropTypes.object,
  status: PropTypes.string,
  regNum: PropTypes.string,
  regDate: PropTypes.instanceOf(Date),
  regUrl: PropTypes.string,
  setRegInfo: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignUser: PropTypes.func.isRequired,
  unAssignUser: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onBuildPackage: PropTypes.func.isRequired
}

export default ProjectActions
