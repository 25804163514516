import './EditFundModal.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, FormFeedback, FormText, Label, Input } from 'reactstrap'
import { Formik, Field, ErrorMessage } from 'formik'
import moment from 'moment'
import DatePickerField from '../DatePickerField/DatePickerField'

const EditFundModal = ({ isOpen, fund, onClose, fundTypes, onAddFund, onEditFund }) => {
  const initialValues = Object.assign({
    name: null,
    type: null,
    isKval: false,
    cancelDate: null,
    aspectValue: null
  }, fund)

  const validateName = value => value ? null : 'Укажите название фонда'
  const validateType = value => value ? null : 'Выберите тип фонда'
  const validateAspectValue = value => value ? null : 'Укажите идентификатор для ЦБ'

  const onSubmit = (values) => {
    const cancelDate = values.cancelDate ? moment(values.cancelDate).format('YYYY-MM-DD') : null

    if (!fund) {
      onAddFund(
        values.name,
        values.type,
        values.isKval || false,
        cancelDate,
        values.aspectValue || null)
    } else {
      onEditFund(
        fund._id,
        values.name,
        values.type,
        values.isKval || false,
        cancelDate,
        values.aspectValue || null)
    }

    onClose()
  }

  return (
    <Modal contentClassName="edit-fund-modal" isOpen={isOpen} toggle={onClose} centered={true} keyboard={true}>
      <ModalHeader toggle={onClose}>
        {fund ? 'Изменить' : 'Зарегистрировать'} фонд
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup className="required position-relative">
                <Label for="name">Название фонда</Label>
                <Input tag={Field} type="text" name="name" validate={validateName} invalid={!!errors.name} />
                {errors.name && <FormFeedback tooltip>{errors.name}</FormFeedback>}
              </FormGroup>
              <FormGroup className="required position-relative">
                <Label for="type">Тип фонда</Label>
                <Input tag={Field} as="select" name="type" className="col-3" validate={validateType} invalid={!!errors.type}>
                  <option></option>
                  { fundTypes.map((type) => {
                      return (
                        <option value={type.code}>{type.name}</option>
                      )
                    })
                  }
                </Input>
                {errors.type && <FormFeedback tooltip>{errors.type}</FormFeedback>}
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input tag={Field} type="checkbox" name="isKval" />
                  Только для квалифицированных инвесторов                
                </Label>
              </FormGroup>
              <FormGroup>
                <Label for="date">Дата возникновения основания прекращения фонда</Label>
                <DatePickerField className="form-control col-3" name="cancelDate" locale="ru" dateFormat="dd.MM.yyyy" autoComplete="off" />
                <FormText>указывается только если фонд находится в процессе прекращения</FormText>
              </FormGroup>
              <FormGroup className="required position-relative">
                <Label for="aspectValue">Идентификатор для ЦБ</Label>
                <Input tag={Field} type="text" name="aspectValue" validate={validateAspectValue} invalid={!!errors.aspectValue} />
                <FormText>Необходимо использовать этот идентификатор для загружаемых отчетов как значение измерения IdAifPifTaxis</FormText>
                {errors.aspectValue && <FormFeedback tooltip>{errors.aspectValue}</FormFeedback>}
              </FormGroup>
              <Button type="submit" color="primary">{fund ? 'Сохранить' : 'Зарегистрировать'}</Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

EditFundModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  fund: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  fundTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddFund: PropTypes.func,
  onEditFund: PropTypes.func
}

export default EditFundModal
