import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import CheckCbr from '../../components/CheckCbr/CheckCbr'
import CheckFormula from '../../components/CheckFormula/CheckFormula'
import EmptyProject from '../../components/EmptyProject/EmptyProject'
import { cbrCheckProject, formulaCheckProject, fixProject, formulaExport } from '../../actions/project'
import './ProjectCheck.css'

class ProjectCheck extends Component {
  async componentDidMount() {
    await this.props.cbrCheckProject(this.props.id)

    this.props.formulaCheckProject(this.props.id)
  }

  render() {
    let { cbrCheckLog, formulaCheckLog, formulaExport, isFormulaProcessed } = this.props
    // todo: rm
    cbrCheckLog = cbrCheckLog || []
    formulaCheckLog = formulaCheckLog || []
    formulaCheckLog = formulaCheckLog.filter((item) => !item.err)

    const errCount = cbrCheckLog.reduce((count, errGroup) => {
      return count + errGroup.entries.length
    }, formulaCheckLog.length)

    return (
      <div className="container">
        <h1 className="step-header">Замечания</h1>

        { isFormulaProcessed && this.renderContextLoader() }

        <div className="bg_white">
          <div className="check-summary">
            <h3 className="check-summary-text">
              <p>Найдено {errCount} замечаний</p>
              <p>Технические ошибки могут быть автоматически исправлены</p>
            </h3>
            { 
              !!formulaCheckLog && !!formulaCheckLog.length &&
              <NavLink to='#' onClick={formulaExport.bind(this, this.props.id)}>Скачать файл с замечаниями</NavLink>
            }
          </div>
        </div>

        { cbrCheckLog && cbrCheckLog.map((entry) => <CheckCbr entry={entry} />) }

        { formulaCheckLog && formulaCheckLog.map((entry) => <CheckFormula entry={entry} />) }
      </div>
    )
  }

  fix() {
    const { fixProject, id } = this.props
    fixProject(id)
  }

  renderContextLoader() {
    return (
      <div className="context-loader">
        <span><i className="fas fa-spinner fa-spin"></i></span>
        Идет проверка контрольных соотношений. Обычно это занимает несколько минут.
      </div>
    )
  }
}

ProjectCheck.propTypes = {
  id: PropTypes.string,
  cbrCheckLog: PropTypes.array.isRequired,
  cbrCheckProject: PropTypes.func.isRequired,
  formulaCheckProject: PropTypes.func.isRequired,
  formulaExport: PropTypes.func.isRequired,
  formulaCheckLog: PropTypes.array.isRequired,
  isFormulaProcessed: PropTypes.bool
}

function mapStateToProps(state, ownProps) {
  return {
    id: state.project.id || ownProps.match.params.projectId,
    cbrCheckLog: state.project.cbrCheckLog,
    formulaCheckLog: state.project.formulaCheckLog,
    isFormulaProcessed: state.loader.isFormulaProcessed
  }
}

export default connect(mapStateToProps, { cbrCheckProject, formulaCheckProject, fixProject, formulaExport })(ProjectCheck)
