import { actions } from 'redux-file-upload'

export const TOGGLE_LOGIN_MODE = 'TOGGLE_LOGIN_MODE'

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE'

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE'

export const USER_RESET_REQUEST = 'USER_RESET_REQUEST'
export const USER_RESET_SUCCESS = 'USER_RESET_SUCCESS'
export const USER_RESET_FAILURE = 'USER_RESET_FAILURE'

export const USER_ANAUTHORIZED = 'USER_ANAUTHORIZED'

export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE'

export const SET_COMPANY_REQUEST = 'SET_COMPANY_REQUEST'
export const SET_COMPANY_SUCCESS = 'SET_COMPANY_SUCCESS'
export const SET_COMPANY_FAILURE = 'SET_COMPANY_FAILURE'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST'
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS'
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE'

export const ADD_FUND_REQUEST = 'ADD_FUND_REQUEST'
export const ADD_FUND_SUCCESS = 'ADD_FUND_SUCCESS'
export const ADD_FUND_FAILURE = 'ADD_FUND_FAILURE'

export const Edit_FUND_REQUEST = 'Edit_FUND_REQUEST'
export const Edit_FUND_SUCCESS = 'Edit_FUND_SUCCESS'
export const Edit_FUND_FAILURE = 'Edit_FUND_FAILURE'

export const DELETE_FUND_REQUEST = 'DELETE_FUND_REQUEST'
export const DELETE_FUND_SUCCESS = 'DELETE_FUND_SUCCESS'
export const DELETE_FUND_FAILURE = 'DELETE_FUND_FAILURE'

export const GET_FUND_TYPES_REQUEST = 'GET_FUND_TYPES_REQUEST'
export const GET_FUND_TYPES_SUCCESS = 'GET_FUND_TYPES_SUCCESS'
export const GET_FUND_TYPES_FAILURE = 'GET_FUND_TYPES_FAILURE'

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST'
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE'

export const PROJECTS_FILTERS_CHANGED = 'PROJECTS_FILTERS_CHANGED'

export const PROJECT_RESTORE_REQUEST = 'PROJECT_RESTORE_REQUEST'
export const PROJECT_RESTORE_SUCCESS = 'PROJECT_RESTORE_SUCCESS'
export const PROJECT_RESTORE_FAILURE = 'PROJECT_RESTORE_FAILURE'

export const CHANGE_PROJECT = 'CHANGE_PROJECT'

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE'

export const PROJECT_ASSIGN_USER_REQUEST = 'PROJECT_ASSIGN_USER_REQUEST'
export const PROJECT_ASSIGN_USER_SUCCESS = 'PROJECT_ASSIGN_USER_SUCCESS'
export const PROJECT_ASSIGN_USER_FAILURE = 'PROJECT_ASSIGN_USER_FAILURE'

export const PROJECT_UNASSIGN_USER_REQUEST = 'PROJECT_UNASSIGN_USER_REQUEST'
export const PROJECT_UNASSIGN_USER_SUCCESS = 'PROJECT_UNASSIGN_USER_SUCCESS'
export const PROJECT_UNASSIGN_USER_FAILURE = 'PROJECT_UNASSIGN_USER_FAILURE'

export const PROJECT_SET_REG_INFO_REQUEST = 'PROJECT_SET_REG_INFO_REQUEST'
export const PROJECT_SET_REG_INFO_SUCCESS = 'PROJECT_SET_REG_INFO_SUCCESS'
export const PROJECT_SET_REG_INFO_FAILURE = 'PROJECT_SET_REG_INFO_FAILURE'

export const PROJECT_SET_COMMENT_REQUEST = 'PROJECT_SET_COMMENT_REQUEST'
export const PROJECT_SET_COMMENT_SUCCESS = 'PROJECT_SET_COMMENT_SUCCESS'
export const PROJECT_SET_COMMENT_FAILURE = 'PROJECT_SET_COMMENT_FAILURE'

export const PROJECT_REMOVE_REQUEST = 'PROJECT_REMOVE_REQUEST'
export const PROJECT_REMOVE_SUCCESS = 'PROJECT_REMOVE_SUCCESS'
export const PROJECT_REMOVE_FAILURE = 'PROJECT_REMOVE_FAILURE'

export const PROJECT_ARCHIVE_REQUEST = 'PROJECT_ARCHIVE_REQUEST'
export const PROJECT_ARCHIVE_SUCCESS = 'PROJECT_ARCHIVE_SUCCESS'
export const PROJECT_ARCHIVE_FAILURE = 'PROJECT_ARCHIVE_FAILURE'

export const OKUD_ASSIGN_USER_REQUEST = 'OKUD_ASSIGN_USER_REQUEST'
export const OKUD_ASSIGN_USER_SUCCESS = 'OKUD_ASSIGN_USER_SUCCESS'
export const OKUD_ASSIGN_USER_FAILURE = 'OKUD_ASSIGN_USER_FAILURE'

export const OKUD_UNASSIGN_USER_REQUEST = 'OKUD_UNASSIGN_USER_REQUEST'
export const OKUD_UNASSIGN_USER_SUCCESS = 'OKUD_UNASSIGN_USER_SUCCESS'
export const OKUD_UNASSIGN_USER_FAILURE = 'OKUD_UNASSIGN_USER_FAILURE'

export const OKUD_SET_STATUS_REQUEST = 'OKUD_SET_STATUS_REQUEST'
export const OKUD_SET_STATUS_SUCCESS = 'OKUD_SET_STATUS_SUCCESS'
export const OKUD_SET_STATUS_FAILURE = 'OKUD_SET_STATUS_FAILURE'

export const OKUD_EDIT_REQUEST = 'OKUD_EDIT_REQUEST'
export const OKUD_EDIT_SUCCESS = 'OKUD_EDIT_SUCCESS'
export const OKUD_EDIT_FAILURE = 'OKUD_EDIT_FAILURE'

export const OKUD_ADD_REQUEST = 'OKUD_ADD_REQUEST'
export const OKUD_ADD_SUCCESS = 'OKUD_ADD_SUCCESS'
export const OKUD_ADD_FAILURE = 'OKUD_ADD_FAILURE'

export const OKUD_REMOVE_REQUEST = 'OKUD_REMOVE_REQUEST'
export const OKUD_REMOVE_SUCCESS = 'OKUD_REMOVE_SUCCESS'
export const OKUD_REMOVE_FAILURE = 'OKUD_REMOVE_FAILURE'

export const OKUD_RESET_REQUEST = 'OKUD_RESET_REQUEST'
export const OKUD_RESET_SUCCESS = 'OKUD_RESET_SUCCESS'
export const OKUD_RESET_FAILURE = 'OKUD_RESET_FAILURE'

export const OKUD_CBR_CHECK_REQUEST = 'OKUD_CBR_CHECK_REQUEST'
export const OKUD_CBR_CHECK_SUCCESS = 'OKUD_CBR_CHECK_SUCCESS'
export const OKUD_CBR_CHECK_FAILURE = 'OKUD_CBR_CHECK_FAILURE'

export const OKUD_FORMULA_CHECK_REQUEST = 'OKUD_FORMULA_CHECK_REQUEST'
export const OKUD_FORMULA_CHECK_SUCCESS = 'OKUD_FORMULA_CHECK_SUCCESS'
export const OKUD_FORMULA_CHECK_FAILURE = 'OKUD_FORMULA_CHECK_FAILURE'

export const OKUD_EXCEL_REQUEST = 'OKUD_EXCEL_REQUEST'
export const OKUD_EXCEL_SUCCESS = 'OKUD_EXCEL_SUCCESS'
export const OKUD_EXCEL_FAILURE = 'OKUD_EXCEL_FAILURE'

export const SELECT_BASE_PROJECT = 'SELECT_BASE_PROJECT'
export const CHANGE_DTS_VERSION = 'CHANGE_DTS_VERSION'
export const CHANGE_NFO_TYPE = 'CHANGE_NFO_TYPE'
export const CHANGE_REPORT_TYPE = 'CHANGE_REPORT_TYPE'
export const CHANGE_PERIOD_TYPE = 'CHANGE_PERIOD_TYPE'
export const CHANGE_PERIOD = 'CHANGE_PERIOD'
export const TOGGLE_IS_TEMPORARY = 'TOGGLE_IS_TEMPORARY'

export const GET_DTS_LIST_REQUEST = 'GET_DTS_LIST_REQUEST'
export const GET_DTS_LIST_SUCCESS = 'GET_DTS_LIST_SUCCESS'
export const GET_DTS_LIST_FAILURE = 'GET_DTS_LIST_FAILURE'

export const GET_EP_LIST_REQUEST = 'GET_EP_LIST_REQUEST'
export const GET_EP_LIST_SUCCESS = 'GET_EP_LIST_SUCCESS'
export const GET_EP_LIST_FAILURE = 'GET_EP_LIST_FAILURE'

export const GET_CURRENCY_LIST_REQUEST = 'GET_CURRENCY_LIST_REQUEST'
export const GET_CURRENCY_LIST_SUCCESS = 'GET_CURRENCY_LIST_SUCCESS'
export const GET_CURRENCY_LIST_FAILURE = 'GET_CURRENCY_LIST_FAILURE'

export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE'

export const PROJECT_DOWNLOAD_REQUEST = 'PROJECT_DOWNLOAD_REQUEST'
export const PROJECT_DOWNLOAD_SUCCESS = 'PROJECT_DOWNLOAD_SUCCESS'
export const PROJECT_DOWNLOAD_FAILURE = 'PROJECT_DOWNLOAD_FAILURE'

export const BUILD_PACKAGE_REQUEST = 'BUILD_PACKAGE_REQUEST'
export const BUILD_PACKAGE_SUCCESS = 'BUILD_PACKAGE_SUCCESS'
export const BUILD_PACKAGE_FAILURE = 'BUILD_PACKAGE_FAILURE'

export const PROJECT_SET_OKUD_CODE_FOR_ADD = 'PROJECT_SET_OKUD_CODE_FOR_ADD'
export const PROJECT_SET_OKUD_ID_FOR_EDIT = 'PROJECT_SET_OKUD_ID_FOR_EDIT'
export const PROJECT_RESET_OKUD_CODE_FOR_ADD = 'PROJECT_RESET_OKUD_CODE_FOR_ADD'
export const PROJECT_RESET_OKUD_ID_FOR_EDIT = 'PROJECT_RESET_OKUD_ID_FOR_EDIT'

export const PROJECT_CLEAR_UPLOAD_ERRORS = 'PROJECT_CLEAR_UPLOAD_ERRORS'

export const PROJECT_RO_REQUEST = 'PROJECT_RO_REQUEST'
export const PROJECT_RO_SUCCESS = 'PROJECT_RO_SUCCESS'
export const PROJECT_RO_FAILURE = 'PROJECT_RO_FAILURE'

export const STEP_GOTO = 'STEP_GOTO'
export const STEP_NEXT = 'STEP_NEXT'
export const STEP_PREV = 'STEP_PREV'

export const VIEW_REQUEST = 'VIEW_REQUEST'
export const VIEW_SUCCESS = 'VIEW_SUCCESS'
export const VIEW_FAILURE = 'VIEW_FAILURE'

export const UPD_CELL = 'UPD_CELL'

export const SAVE_REQUEST = 'SAVE_REQUEST'
export const SAVE_SUCCESS = 'SAVE_SUCCESS'
export const SAVE_FAILURE = 'SAVE_FAILURE'

export const EDITOR_START_REQUEST = 'EDITOR_START_REQUEST'
export const EDITOR_START_SUCCESS = 'EDITOR_START_SUCCESS'
export const EDITOR_START_FAILURE = 'EDITOR_START_FAILURE'

export const EDITOR_CANCEL = 'EDITOR_CANCEL'

export const EDITOR_ADD_ROW = 'EDITOR_ADD_ROW'
export const EDITOR_REMOVE_ROW = 'EDITOR_REMOVE_ROW'
export const EDITOR_COPY_ROW = 'EDITOR_COPY_ROW'

export const DRAFT_APPLY_REQUEST = 'DRAFT_APPLY_REQUEST'
export const DRAFT_APPLY_SUCCESS = 'DRAFT_APPLY_SUCCESS'
export const DRAFT_APPLY_FAILURE = 'DRAFT_APPLY_FAILURE'

export const DRAFT_DROP_REQUEST = 'DRAFT_DROP_REQUEST'
export const DRAFT_DROP_SUCCESS = 'DRAFT_DROP_SUCCESS'
export const DRAFT_DROP_FAILURE = 'DRAFT_DROP_FAILURE'

export const CBR_CHECK_REQUEST = 'CBR_CHECK_REQUEST'
export const CBR_CHECK_SUCCESS = 'CBR_CHECK_SUCCESS'
export const CBR_CHECK_FAILURE = 'CBR_CHECK_FAILURE'

export const FIX_REQUEST = 'FIX_REQUEST'
export const FIX_SUCCESS = 'FIX_SUCCESS'
export const FIX_FAILURE = 'FIX_FAILURE'

export const FORMULA_CHECK_REQUEST = 'FORMULA_CHECK_REQUEST'
export const FORMULA_CHECK_SUCCESS = 'FORMULA_CHECK_SUCCESS'
export const FORMULA_CHECK_FAILURE = 'FORMULA_CHECK_FAILURE'

export const FORMULA_EXPORT_REQUEST = 'FORMULA_EXPORT_REQUEST'
export const FORMULA_EXPORT_SUCCESS = 'FORMULA_EXPORT_SUCCESS'
export const FORMULA_EXPORT_FAILURE = 'FORMULA_EXPORT_FAILURE'

export const FILE_REMOVE_REQUEST = 'FILE_REMOVE_REQUEST'
export const FILE_REMOVE_SUCCESS = 'FILE_REMOVE_SUCCESS'
export const FILE_REMOVE_FAILURE = 'FILE_REMOVE_FAILURE'

export const FILE_DOWNLOAD_REQUEST = 'FILE_DOWNLOAD_REQUEST'
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS'
export const FILE_DOWNLOAD_FAILURE = 'FILE_DOWNLOAD_FAILURE'

export const FILE_UPLOAD_PROGRESS = actions.FILE_UPLOAD_PROGRESS
export const FILE_UPLOAD_COMPLETE = actions.FILE_UPLOAD_COMPLETE
export const FILE_UPLOAD_ERROR = actions.FILE_UPLOAD_ERROR

export const FETCH_TEST = 'FETCH_TEST'; // eslint-disable-line import/prefer-default-export
