import ru from "date-fns/locale/ru"
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn'
  
const monthValues = {
  narrow: ["Я", "Ф", "М", "А", "М", "И", "И", "А", "С", "О", "Н", "Д"],
  abbreviated: [
    "янв.",
    "фев.",
    "март",
    "апр.",
    "май",
    "июнь",
    "июль",
    "авг.",
    "сент.",
    "окт.",
    "нояб.",
    "дек."
  ],
  wide: [
    "январь",
    "февраль",
    "март",
    "апрель",
    "май",
    "июнь",
    "июль",
    "август",
    "сентябрь",
    "октябрь",
    "ноябрь",
    "декабрь"
  ]
}

ru.localize.month = buildLocalizeFn({
  values: monthValues,
  defaultWidth: 'wide',
  defaultFormattingWidth: 'wide'
})

export default ru