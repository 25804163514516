import React from 'react'
import PropTypes from 'prop-types'
import { Treebeard } from 'react-treebeard'
import style from './theme'
import './ProjectIndex.css'

const ProjectIndex = ({ tableList, selectedTable, onSelect }) => {
  const data = buildTreeIndex(tableList, selectedTable)

  const onToggle = (node, toggled) => {
    // node.active = true

    if (node.tableId) {
      document.getElementById(node.tableId).scrollIntoView()
    } else {
      window.scrollTo(0, 0)
    }

    // onSelect(node.tableId)
  }

  return (
    <div className="project-index">
      <Treebeard
        data={data}
        onToggle={onToggle}
        style={style}
      />
    </div>
  )
}

function buildTreeIndex(tableList, selectedTable) {
  const index = tableList.map((table) => {
    return {
      tableId: table.code,
      name: `${table.name}`,
      active: table.code === selectedTable
    }
  })

  return index
}

ProjectIndex.propTypes = {
  tableList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,

  selectedTable: PropTypes.string.isRequired,

  onSelect: PropTypes.func.isRequired
}

export default ProjectIndex
