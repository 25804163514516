'use ustrict'

import fetch from 'cross-fetch'
import { anauthorize } from './user'
import * as types from './types'
import * as utils from './utils'

function requestView(okudId) {
	return {
		type: types.VIEW_REQUEST,
		id: okudId
	}
}

function recieveView(res) {
	return {
		type: types.VIEW_SUCCESS,
		view: res.view
	}
}

function failedView(err) {
	return {
		type: types.VIEW_FAILURE,
		err
	}
}

export function getView(okudId) {
	return dispatch => {
		dispatch(requestView(okudId))
		return fetch(`/api/okud/${okudId}/view`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveView(json)))
			.catch(err => dispatch(failedView(err)))
	}
}

function requestEditOkud() {
	return {
		type: types.OKUD_EDIT_REQUEST
	}
}

function recieveEditOkud(okud) {
	return {
		type: types.OKUD_EDIT_SUCCESS,
		okud
	}
}

function failedEditOkud(err) {
	return {
		type: types.OKUD_EDIT_FAILURE,
		err
	}
}

export function editOkud(okudId, data) {
	const params = {
		method: 'PUT',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	}

	return dispatch => {
		dispatch(requestEditOkud())
		return fetch(`/api/okud/${okudId}/dates`, params)
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveEditOkud(json)))
			.catch(err => dispatch(failedEditOkud(err)))
	}
}

function requestRemoveOkud() {
	return {
		type: types.OKUD_REMOVE_REQUEST
	}
}

function recieveRemoveOkud(id) {
	return {
		type: types.OKUD_REMOVE_SUCCESS,
		id
	}
}

function failedRemoveOkud(err) {
	return {
		type: types.OKUD_REMOVE_FAILURE,
		err
	}
}

export function removeOkud(id) {
	const params = {
		method: 'DELETE',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	}

	return dispatch => {
		dispatch(requestRemoveOkud())
		return fetch(`/api/okud/${id}`, params)
			.then(res => handleErrors(res, dispatch))
			.then(res => dispatch(recieveRemoveOkud(id)))
			.catch(err => dispatch(failedRemoveOkud(err)))
	}
}

function requestResetOkud() {
	return {
		type: types.OKUD_RESET_REQUEST
	}
}

function recieveResetOkud(id) {
	return {
		type: types.OKUD_RESET_SUCCESS,
		id
	}
}

function failedResetOkud(err) {
	return {
		type: types.OKUD_RESET_FAILURE,
		err
	}
}

export function resetOkud(id) {
	const params = {
		method: 'PUT',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	}

	return dispatch => {
		dispatch(requestResetOkud())
		return fetch(`/api/okud/${id}/reset`, params)
			.then(res => handleErrors(res, dispatch))
			.then(res => dispatch(recieveResetOkud(id)))
			.catch(err => dispatch(failedResetOkud(err)))
	}
}

function requestCbrCheckOkud() {
	return {
		type: types.OKUD_CBR_CHECK_REQUEST
	}
}

function recieveCbrCheckOkud(res) {
	return {
		type: types.OKUD_CBR_CHECK_SUCCESS,
		cbrCheckLog: res.cbrCheckLog
	}
}

function failedCbrCheckOkud(err) {
	return {
		type: types.OKUD_CBR_CHECK_FAILURE,
		err
	}
}

export function cbrCheckOkud(id) {
	return dispatch => {
		dispatch(requestCbrCheckOkud())
		return fetch(`/api/okud/${id}/check`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveCbrCheckOkud(json)))
			.catch(err => dispatch(failedCbrCheckOkud(err)))
	}
}

function requestFormulaCheckOkud() {
	return {
		type: types.OKUD_FORMULA_CHECK_REQUEST
	}
}

function recieveFormulaCheckOkud(res) {
	return {
		type: types.OKUD_FORMULA_CHECK_SUCCESS,
		formulaCheckLog: res.formulaCheckLog
	}
}

function failedFormulaCheckOkud(err) {
	return {
		type: types.OKUD_FORMULA_CHECK_FAILURE,
		err
	}
}

export function formulaCheckOkud(id) {
	return dispatch => {
		dispatch(requestFormulaCheckOkud())
		return fetch(`/api/okud/${id}/formula`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveFormulaCheckOkud(json)))
			.catch(err => dispatch(failedFormulaCheckOkud(err)))
	}
}

function requestOkudDownload() {
	return {
		type: types.PROJECT_DOWNLOAD_REQUEST
	}
}

function recieveOkudDownload(project) {
	return {
		type: types.PROJECT_DOWNLOAD_SUCCESS
	}
}

function failedOkudDownload(err) {
	return {
		type: types.PROJECT_DOWNLOAD_FAILURE,
		err
	}
}

export function okudDownload(id) {
	return dispatch => {
		dispatch(requestOkudDownload())
		return fetch(`/api/okud/${id}/result`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => {
				const link = document.createElement('a')

				const url = window.URL.createObjectURL(res._bodyBlob)
				link.href = url

				const filename = utils.getFileNameFromResponse(res)
				link.setAttribute('download', filename)
				
				document.body.appendChild(link)
				link.click()

				dispatch(recieveOkudDownload())
			})
			.catch(err => dispatch(failedOkudDownload(err)))
	}
}

function requestGetExcel() {
	return {
		type: types.OKUD_EXCEL_REQUEST
	}
}

function recieveGetExcel(project) {
	return {
		type: types.OKUD_EXCEL_SUCCESS
	}
}

function failedGetExcel(err) {
	return {
		type: types.OKUD_EXCEL_FAILURE,
		err
	}
}

export function getExcel(id) {
	return dispatch => {
		dispatch(requestGetExcel())
		return fetch(`/api/okud/${id}/excel`, { method: 'GET' })
			.then(res => handleErrors(res, dispatch))
			.then(res => {
				const link = document.createElement('a')

				const url = window.URL.createObjectURL(res._bodyBlob)
				link.href = url

				const filename = utils.getFileNameFromResponse(res)
				link.setAttribute('download', filename)
				
				document.body.appendChild(link)
				link.click()

				dispatch(recieveGetExcel())
			})
			.catch(err => dispatch(failedGetExcel(err)))
	}
}

function requestAssignUser() {
	return {
		type: types.OKUD_ASSIGN_USER_REQUEST
	}
}

function recieveAssignUser(okudId, assigneeId, permissions, status) {
	return {
		type: types.OKUD_ASSIGN_USER_SUCCESS,
		okudId,
		assigneeId,
		permissions,
		status
	}
}

function failedAssignUser(err) {
	return {
		type: types.OKUD_ASSIGN_USER_FAILURE,
		err
	}
}

export function assignUser(okudId, userId) {
	return dispatch => {
		dispatch(requestAssignUser())
		return fetch(`/api/okud/${okudId}/assign/${userId}`, { method: 'PUT' })
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveAssignUser(okudId, userId, json.permissions, json.status)))
			.catch(err => dispatch(failedAssignUser(err)))
	}
}

function requestUnAssignUser() {
  return {
    type: types.OKUD_UNASSIGN_USER_REQUEST
  }
}

function recieveUnAssignUser(okudId, permissions, status) {
  return {
    type: types.OKUD_UNASSIGN_USER_SUCCESS,
    okudId,
    permissions,
    status
  }
}

function failedUnAssignUser(err) {
  return {
    type: types.OKUD_UNASSIGN_USER_FAILURE,
    err
  }
}

export function unAssignUser(okudId) {
  return dispatch => {
    dispatch(requestUnAssignUser())
    return fetch(`/api/okud/${okudId}/assign/`, { method: 'DELETE' })
      .then(res => utils.handleErrors(res, dispatch))
      .then(res => res.json())
      .then(json => dispatch(recieveUnAssignUser(okudId, json.permissions, json.status)))
      .catch(err => dispatch(failedUnAssignUser(err)))
  }
}

function requestSetStatus() {
	return {
		type: types.OKUD_SET_STATUS_REQUEST
	}
}

function recieveSetStatus(okudId, status, permissions) {
	return {
		type: types.OKUD_SET_STATUS_SUCCESS,
		okudId,
		status,
		permissions
	}
}

function failedSetStatus(err) {
	return {
		type: types.OKUD_SET_STATUS_FAILURE,
		err
	}
}

export function setStatus(okudId, status) {
	const params = {
		method: 'PUT',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ status })
	}

	return dispatch => {
		dispatch(requestSetStatus())
		return fetch(`/api/okud/${okudId}/status`, params)
			.then(res => utils.handleErrors(res, dispatch))
			.then(res => res.json())
			.then(json => dispatch(recieveSetStatus(okudId, status, json.permissions)))
			.catch(err => dispatch(failedSetStatus(err)))
	}
}

async function handleErrors(res, dispatch) {
	if (res.ok) {
		return res
	}

	if (res.status === 401) {
		dispatch(anauthorize())
	}

	const text = await res.text()
	throw Error(text || res.statusText)
}
