import './CompanyUserList.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Badge, Button } from 'reactstrap'
import UserInviteModal from '../UserInviteModal/UserInviteModal'

const CompanyUserList = ({ users, onUserInvite }) => {
  const [isInviteModalOpen, setInviteModalOpen] = useState(false)

  return (
    <div className="group company-user-list">
      <div className="group-header">
        Пользователи
        <Button className="float-right" size="sm" onClick={() => setInviteModalOpen(true)}>Пригласить пользователя</Button>
      </div>
      <div className="group-body">
        <Table>
          <tbody>
            {
              users.map((user) => {
                return (
                  <tr>
                    <td>
                      {user.name}
                      { user.isCurrent && <Badge className="badge-curr-user" color="info">Это вы</Badge> }
                    </td>
                    <td>
                      {user.email}
                    </td>
                    <td>
                      {getRoleDisplayName(user.role)}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>

      <UserInviteModal
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInvite={onUserInvite} />
    </div>
  )
}

function getRoleDisplayName(role) {
  switch (role) {
    case 'admin':
      return 'Администратор'
    default:
      return 'Пользователь'
  }
}

CompanyUserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUserInvite: PropTypes.func.isRequired,
}

export default CompanyUserList