import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import './Loader.css'

class Loader extends Component {
  render() {
    const { active, label, hideBackdrop } = this.props
    let className = active ? 'loader-active' : 'loader-inactive'
    if (active && hideBackdrop) {
      className += ' hide-backdrop'
    }

    return (
    	<div className={className}>
        <div className="container">
          <div className="loader-body">
            <span><i className="fas fa-spinner fa-spin"></i></span>
            <span className="loader-label">{label}</span>
          </div>
        </div>
      </div>
  	)
  }
}

Loader.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  hideBackdrop: PropTypes.bool
}

export default Loader
