import React from 'react'
import classNames from 'classnames'

const EditableCell = ({
  cell: { value: initialValue = { value: '' } },
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const onFocus = (evt) => {
    let sel, range
    if (window.getSelection && document.createRange) {
        range = document.createRange()
        range.selectNodeContents(evt.target)
        sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
    } else if (document.body.createTextRange) {
        range = document.body.createTextRange()
        range.moveToElementText(evt.target)
        range.select()
    }

    if (!evt.target.prevValue) {
      evt.target.prevValue = initialValue.value
    }
  }

  const onBlur = (evt) => {
    const value = evt.target.textContent

    if (evt.target.prevValue !== value) {
      if (id.startsWith('d') && value === '') {
        evt.target.classList.add('edit-cell-error')
      } else {
        evt.target.classList.remove('edit-cell-error')
      }

      evt.target.prevValue = value
      updateMyData(index, id, { value })
    }
  }

  const onKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      evt.target.blur()
    }
  }

  // const onPaste = (evt) => {
  //   // Stop data actually being pasted into div
  //   evt.stopPropagation()
  //   evt.preventDefault()

  //   // Get pasted data via clipboard API
  //   const clipboardData = evt.clipboardData || window.clipboardData
  //   const pastedData = clipboardData.getData('Text')

  //   const data = pastedData.split('\n').map((s) => s.split('\t'))
  //   // updateMyData(index, id, data)

  //   // debugger
  //   // const tbody = evt.target.parentNode.parentNode.parentNode
  //   // for (let [i, rowData] of data) {
  //   //   for (let cellData of rowData)
  //   // }


  //   // // evt.target.textContent

  //   // console.log(data)
  // }

  const cellClassNames = classNames('edit-cell-value', { 'edit-cell-error': id.startsWith('d') && initialValue.value === '' })

  return (
    <div 
      className={cellClassNames}
      contentEditable 
      suppressContentEditableWarning 
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyPress={onKeyPress}>
        {initialValue.value}
    </div>
  )
}

export default EditableCell
