import React from 'react'
import PropTypes from 'prop-types'
import InlineEditor from '../InlineEditor/InlineEditor'
import './ProjectInfo.css'

const ProjectInfo = ({ ep, dtsVersion, nfoType, reportType, periodEnd, periodName, comment, onSetComment }) => {
  return (
    <div className="project-section report-info">
      <dl>
        <dt>Таксономия</dt>
        <dd>{dtsVersion}</dd>

        <dt>Тип НФО</dt>
        <dd>{nfoType}</dd>

        <dt>Тип отчета</dt>
        <dd>{reportType}</dd>

        <dt>Точка входа</dt>
        <dd>{ep}</dd>

        <dt>Отчетный период</dt>
        <dd>{periodName || periodEnd}</dd>

        <dt>Комментарий</dt>
        <dd>
          <InlineEditor
            onSetText={onSetComment}
            placeholder="Добавить"
            text={comment}
          />
        </dd>
      </dl>
    </div>
  )
}

ProjectInfo.propTypes = {
  ep: PropTypes.string.isRequired,
  dtsVersion: PropTypes.string.isRequired,
  nfoType: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  periodEnd: PropTypes.object.isRequired,
  periodName: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  onSetComment: PropTypes.func.isRequired
}

export default ProjectInfo