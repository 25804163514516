import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import './InlineEditor.css'

const DEFAULT_SAVE_BUTTON_NAME = 'Editable_Label__SAVE_BUTTON'
const ENTER_KEY_CODE = 13
const ESC_KEY_CODE = 27

const InlineEditor = ({ text, placeholder, onSetText }) => {
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef()

  const handleFocus = () => {
    setIsEditing(true)
  }

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement.getAttribute('name') === DEFAULT_SAVE_BUTTON_NAME) {
        onSetText.call(this, inputRef.current.value)
      }

      setIsEditing(false)
    }, 50)
  }

  const handleKeyDown = (evt) => {
    if (evt.keyCode === ENTER_KEY_CODE) {
      onSetText.call(this, inputRef.current.value)
      setIsEditing(false)
    } else if (evt.keyCode === ESC_KEY_CODE) {
      setIsEditing(false)
    }
  }

  return (
    <div class="inline-editor">
      {
        isEditing 
        ? <input type="text"
            class="form-control"
            ref={inputRef}
            defaultValue={text}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            autoFocus />
        : <label onClick={handleFocus}>
            {text || placeholder}
          </label>
      }
      
      {
        isEditing &&
        <button name={DEFAULT_SAVE_BUTTON_NAME} class="fas fa-check"></button>
      }
   </div>
  )
}

InlineEditor.propTypes = {
  onSetText: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default InlineEditor