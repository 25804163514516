import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import './Step.css'

class Step extends Component {
  render() {
    const { name, num, href, isActive } = this.props

    let className = 'flex step'
    if (isActive) {
      className += ' step-active'
    }

    return (
      <div className="col-12 col-sm-6 col-md-3 px-3 px-md-2">
        <NavLink className={className} to={href}>
          <div className="step-num-wrap flex">
            <div className="step-num flex">{num}</div>
          </div>
          <div className="step-name">{name}</div>
        </NavLink>
      </div>
    )
  }
}

Step.propTypes = {
  name:PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
  isActive: PropTypes.bool
}

export default Step
