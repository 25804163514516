import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactDom from 'react-dom'
import { ListGroup, ListGroupItem, Nav, NavItem, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment'
import OkudAddModal from '../OkudAddModal/OkudAddModal'
import OkudEditModal from '../OkudEditModal/OkudEditModal'
import OkudStatus from '../OkudStatus/OkudStatus'
import UserSelect from '../UserSelect/UserSelect'
import './ProjectOkud.css'

const ProjectOkud = ({
  id,
  periodStart,
  periodEnd,
  users,
  okuds,
  okudCodeForAdd,
  setOkudCodeForAdd,
  resetOkudCodeForAdd,
  addOkud,
  okudIdForEdit,
  setOkudIdForEdit,
  resetOkudIdForEdit,
  editOkud,
  resetOkud, 
  removeOkud,
  onOkudDateChange,
  onDownload,
  assignUser,
  unAssignUser,
  setStatus
}) => {

  const onDownloadClick = (okudId, evt) => {
    evt.preventDefault()
    onDownload.call(this, okudId)
  }

  const [isAddModalShow, setAddModalShow] = useState(false)
  const [isEditModalShow, setEditModalShow] = useState(false)

  const onOcudAddClick = (okud, evt) => {
    setOkudCodeForAdd(okud.code)

    setAddModalShow(true)
  }

  const onOcudEditClick = (okud, evt) => {
    setOkudIdForEdit(okud._id)

    setEditModalShow(true)
  }

  const okudForAdd = okuds.find((okud) => okud.code === okudCodeForAdd)
  let okudForEdit = null
  for (let okud of okuds) {
    okudForEdit = okud.list.find((item) => item._id === okudIdForEdit)
    if (okudForEdit) break
  }

  const onOkudAddClose = () => {
    resetOkudCodeForAdd()
    setAddModalShow(false)
  }

  const onOkudEditClose = () => {
    resetOkudIdForEdit()
    setEditModalShow(false)
  }

  const onResetClick = (id, evt) => {
    if (window.confirm('Пожалуйста, подтвердите удаление данных отчетной формы')) {
      resetOkud(id)
    }
  }

  const onDeleteClick = (id, evt) => {
    if (window.confirm('Пожалуйста, подтвердите удаление отчетной формы')) {
      removeOkud(id)
    }
  }

  return (
    <div className="project-section project-okuds">
      <h5>Отчетные формы</h5>
      <ListGroup>
        {
          okuds && okuds.map((okud) => {
            const hasAspects = !!okud.aspects.length
            
            return (
              <ListGroupItem>
                <div className="project-okud">
                  <div className="project-okud-title">
                    <span className="project-okud-code">[{okud.code}]</span>
                    <span className="project-okud-label">{okud.label}</span>

                    {
                      hasAspects &&
                      <span className="pseudo-link project-okud-add" onClick={onOcudAddClick.bind(this, okud)}>добавить</span>
                    }
                  </div>
                  
                  {
                    okud.list.map((item) => {
                      const editLabel = item.permissions.canEdit ? 'редактор' : 'просмотр'

                      return (
                        <div className="project-okud-item">
                          <Nav className="project-okud-actions">
                            <NavItem>
                              <NavLink to={`/project/${id}/okud/${item._id}/view`}>{editLabel}</NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink to={`/project/${id}/okud/${item._id}/check`}>замечания</NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink to='#' onClick={onDownloadClick.bind(this, item._id)}>скачать</NavLink>
                            </NavItem>
                            {
                              item.permissions.canRemove &&
                              <NavItem>
                                {
                                  hasAspects ?
                                  <NavLink to='#' className='project-okud-rm-link' onClick={onDeleteClick.bind(this, item._id)}>удалить</NavLink>
                                  :
                                  <NavLink to='#' className='project-okud-rm-link' onClick={onResetClick.bind(this, item._id)}>очистить</NavLink>
                                }
                              </NavItem>
                            }
                          </Nav>

                          <dl>
                            <dt>Начало отчетного периода</dt>
                            <dd>
                              {
                               item.info.periodStart ? 
                                <Moment format="DD.MM.YYYY">{item.info.periodStart}</Moment> :
                                'Не определен'
                              }
                            </dd>

                            <dt>Конец отчетного периода</dt>
                            <dd><Moment format="DD.MM.YYYY">{item.info.periodEnd}</Moment></dd>

                            <span className="pseudo-link project-okud-date-change" onClick={onOcudEditClick.bind(this, item)}>изменить</span>
                          </dl>

                          <dl>
                          {
                            okud.aspects.map((aspect) => {
                              const aspectValue = item.aspects.find((av) => aspect.code === av.name)
                              const value = aspectValue ? aspectValue.value : ''

                              return (
                                <span>
                                  <dt>{aspect.name}</dt>
                                  <dd>{value}</dd>
                                </span>
                              )
                            })
                          }
                          </dl>

                          <dl>
                            <dt>Исполнитель</dt>
                            <dd>
                              <UserSelect
                                users={users}
                                selectedId={item.assigneeId}
                                disabled={!item.permissions.canAssign}
                                onChange={assignUser.bind(this, item._id)}
                                onRemove={unAssignUser.bind(this, item._id)}
                              />
                            </dd>

                            <OkudStatus
                              status={item.status}
                              canFinish={item.permissions.canFinish}
                              canRevert={item.permissions.canRevert}
                              setStatus={setStatus.bind(this, item._id)}
                            />
                          </dl>
                        </div>
                      )
                    })
                  }
                </div>
              </ListGroupItem>
            )
          })
        }
      </ListGroup>

      {
        okudForAdd &&
        <OkudAddModal
          isOpen={isAddModalShow}
          close={onOkudAddClose}
          projectId={id}
          code={okudForAdd.code}
          aspects={okudForAdd.aspects}
          periodStart={periodStart}
          periodEnd={periodEnd}
          onCreate={addOkud}
        />
      }

      {
        okudForEdit &&
        <OkudEditModal
          isOpen={isEditModalShow}
          close={onOkudEditClose}
          okudId={okudIdForEdit}
          aspects={okudForEdit.aspects}
          periodStart={okudForEdit.info.periodStart}
          periodEnd={okudForEdit.info.periodEnd}
          onSave={editOkud}
        />
      }

    </div>
  )
}

ProjectOkud.propTypes = {
  id: PropTypes.string.isRequired,

  periodStart: PropTypes.object.isRequired,
  periodEnd: PropTypes.object.isRequired,

  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  
  okuds: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    label: PropTypes.string,
    aspects: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })),
    list: PropTypes.arrayOf(PropTypes.object)
  })).isRequired,

  okudCodeForAdd: PropTypes.string.isRequired,
  setOkudCodeForAdd: PropTypes.func.isRequired,
  resetOkudCodeForAdd: PropTypes.func.isRequired,
  addOkud: PropTypes.func.isRequired,

  okudIdForEdit: PropTypes.string.isRequired,
  setOkudIdForEdit: PropTypes.func.isRequired,
  resetOkudIdForEdit: PropTypes.func.isRequired,
  editOkud: PropTypes.func.isRequired,
  resetOkud: PropTypes.func.isRequired,
  removeOkud: PropTypes.func.isRequired,

  onOkudDateChange: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  assignUser: PropTypes.func.isRequired,
  unAssignUser: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired
}

export default ProjectOkud
