import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactDom from 'react-dom'
import { ListGroup, ListGroupItem, Nav, NavItem, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import Moment from 'react-moment'

const OkudEditModal = ({ isOpen, close, okudId, aspects, periodStart, periodEnd, onSave }) => {
  const onSubmit = (evt) => {
    evt.preventDefault()

    const formData = new FormData(evt.target)
    const data = { aspects: [] }
    formData.forEach((value, name) => {
      switch (name) {
        case 'okudId':
        case 'periodStart':
        case 'periodEnd':
          data[name] = value
          break
        default:
          data.aspects.push({ name, value })
          break
      }
      
    })

    onSave(okudId, data)

    close()
  }

  const periodStartValue = moment(periodStart).format('YYYY-MM-DD')
  const periodEndValue = moment(periodEnd).format('YYYY-MM-DD')
  const [periodStartState, setPeiodStart] = useState(periodStartValue)
  const [periodEndState, setPeiodEnd] = useState(periodEndValue)

  return (
    <Modal isOpen={isOpen} toggle={close} centered={true} keyboard={true}>
      <ModalHeader toggle={close}/>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Input type="hidden" name="okudId" id="okudId" value={okudId} />

          {
            // aspects.map((aspect) => {
            //   return (
            //     <FormGroup>
            //       <Label for={aspect.code}>{aspect.name}</Label>
            //       <Input type="text" name={aspect.code} id={aspect.code} value={aspect.value} />
            //     </FormGroup>
            //   )
            // })
          }
          
          <FormGroup>
            <Label for="periodStart">Начало отчетного периода</Label>
            <Input type="date" name="periodStart" id="periodStart" value={periodStartState} onChange={(evt) => setPeiodStart(evt.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label for="periodEnd">Конец отчетного периода</Label>
            <Input type="date" name="periodEnd" id="periodEnd"  value={periodEndState} onChange={(evt) => setPeiodEnd(evt.target.value)} />
          </FormGroup>

          <Button color="primary">Применить</Button>
        </Form>
      </ModalBody>
      <ModalFooter>
        {/*<Button color="primary" onClick={onSubmit}>Добавить</Button>*/}
      </ModalFooter>
    </Modal>
  )
}

OkudEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,

  okudId: PropTypes.string.isRequired,

  aspects: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),

  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string.isRequired,
  
  onSave: PropTypes.func.isRequired
}

export default OkudEditModal
