import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProjectManualCreate from '../../components/ProjectManualCreate/ProjectManualCreate'
import ProjectSelect from '../../components/ProjectSelect/ProjectSelect'
import { FileUpload } from 'redux-file-upload'
import FileDropZone from '../../components/FileDropZone/FileDropZone'
import { changeDtsVersion, changeNfoType, changeReportType, changePeriodType, changePeriod, toggleIsTemporary, getDtsList, getCurrencyList } from '../../actions/ui'
import { createProject, selectBaseProject } from '../../actions/project'
import { getProjects } from '../../actions/projects'

class ProjectCreate extends Component {

	componentDidMount() {
		this.props.getDtsList()
		this.props.getCurrencyList()

		if (!this.props.projects.length) {
			// TODO: add infinite scroll abiliti to ProjectSelect component
			const page = -1
			this.props.getProjects(page)
		}
	}

	render() {
		const {
			dtsList,
			nfoList,
			reportTypeList,
			periodTypeList,
			epList,
			currencyList,
			baseProjectId,
			dtsVersionCode,
			nfoTypeCode,
			reportTypeCode,
			periodTypeCode,
			periodEnd,
			ep,
			isTemporary,
			currency,
			decimals,
			idAifPif,
			idUkAifPif,
			projects,
			changeDtsVersion,
			changeNfoType,
			changeReportType,
			changePeriodType,
			changePeriod,
			toggleIsTemporary,
			createProject,
			selectBaseProject,
			getProjects
		} = this.props

		return (
			<div className="container">
				<h1 className="step-header">Новый отчет</h1>

				<ProjectSelect
					projects={projects.list}
					selectedId={baseProjectId}
					onSelect={selectBaseProject.bind(this)}
				/>

				<ProjectManualCreate 
					dtsList={dtsList}
					nfoList={nfoList}
					reportTypeList={reportTypeList}
					periodTypeList={periodTypeList}
					currencyList={currencyList}
					onDtsChange={changeDtsVersion.bind(this)}
					onNfoChange={changeNfoType.bind(this)}
					onReportTypeChange={changeReportType.bind(this)}
					onPeriodTypeChange={changePeriodType.bind(this)}
					onPeriodChange={changePeriod.bind(this)}
					onIsTemporaryChange={toggleIsTemporary.bind(this)}
					
					baseProjectId={baseProjectId}
					dtsVersionCode={dtsVersionCode}
					nfoTypeCode={nfoTypeCode}
					reportTypeCode={reportTypeCode}
					periodTypeCode={periodTypeCode}
					periodEnd={periodEnd}
					ep={ep}
					currency={currency}
					decimals={decimals}
					isTemporary={isTemporary}
					
					onCreate={createProject.bind(this)}
				/>

				<hr />

				<FileUpload
					allowedFileTypes={['xml', 'xbrl']}
					multiple={true}
					dropzoneId="dropZone"
					url={`/api/project/upload?temp=${isTemporary ? 1 : 0}`}
				>
					<FileDropZone />
				</FileUpload>
			</div>
		)
	}
}

ProjectCreate.propTypes = {
	dtsList: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})).isRequired,

	nfoList: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})).isRequired,

	reportTypeList: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})).isRequired,

	periodTypeList: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})).isRequired,

	currencyList: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})).isRequired,

	baseProjectId: PropTypes.string,
	dtsVersionCode: PropTypes.string,
	nfoTypeCode: PropTypes.string,
	reportTypeCode: PropTypes.string,
	periodEnd: PropTypes.string,
	ep: PropTypes.string,
	currency: PropTypes.string,
	decimals: PropTypes.number,
	isTemporary: PropTypes.bool,

	projects: PropTypes.arrayOf(PropTypes.shape({
	    id: PropTypes.string.isRequired,
	    info: PropTypes.object.isRequired
	})).isRequired
}

function mapStateToProps(state, ownProps) {
	return { 
		dtsList: state.newp.dtsList,
		nfoList: state.newp.nfoList,
		reportTypeList: state.newp.reportTypeList,
		periodTypeList: state.newp.periodTypeList,
		epList: state.newp.epList,
		currencyList: state.newp.currencyList,

		baseProjectId: state.newp.baseProjectId,
		dtsVersionCode: state.newp.dtsVersionCode,
		nfoTypeCode: state.newp.nfoTypeCode,
		reportTypeCode: state.newp.reportTypeCode,
		periodTypeCode: state.newp.periodTypeCode,
		periodEnd: state.newp.periodEnd,
		ep: state.newp.ep,
		currency: state.newp.currency,
		decimals: state.newp.decimals,
		isTemporary: state.newp.isTemporary,

		projects: state.projects
	}
}

export default connect(mapStateToProps, {
	changeDtsVersion,
	changeNfoType,
	changeReportType,
	changePeriodType,
	changePeriod,
	toggleIsTemporary,
	getDtsList,
	getCurrencyList,
	createProject,
	selectBaseProject,
	getProjects
})(ProjectCreate)
