import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from '../ProjectManualCreate/locale'
import moment from 'moment'
import './CbrRegInfoModal.css'

registerLocale('ru', ru)

const CbrRegInfoModal = ({ isOpen, onClose, onSave }) => {
  const [date, setDate] = useState(null)

  const onSubmit = (evt) => {
    evt.preventDefault()

    const formData = new FormData(evt.target)
    const regNum = formData.get('regNum')
    const regDate = moment(date).format('YYYY-MM-DD')
    const regUrl = formData.get('regUrl')
    onSave(regNum, regDate, regUrl)

    onClose()
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true} keyboard={true}>
      <ModalHeader toggle={onClose}/>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label for="regNum">Рег номер</Label>
            <Input type="text" name="regNum" id="regNum" placeholder="Рег номер" />
          </FormGroup>

          <FormGroup>
            <Label for="regDate">Дата регистрации</Label>
            <DatePicker sm={4} className="form-control" locale='ru' selected={date} onChange={setDate} placeholderText="Дата регистрации" dateFormat='dd.MM.yyyy' />
          </FormGroup>

          <FormGroup>
            <Label for="regUrl">Ссылка на ЛК ЦБ</Label>
            <Input type="text" name="regUrl" id="regUrl" placeholder="Ссылка на ЛК ЦБ" />
          </FormGroup>

          <Button color="primary">Сохранить</Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

CbrRegInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default CbrRegInfoModal
